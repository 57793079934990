import cloneDeep from "lodash/cloneDeep";
import { IBannerList, ILink, ITabTree } from "src/types/config";
export interface Config {
  tabTree: ITabTree[];
  activity: Activity[];
  bannerList: IBannerList[];
}

export interface Activity {
  hasCustomize?: boolean;
  title: string;
  list?: ProductList[];
  moreLink: ILink<Params>;
  id?: number;
}

export interface Params {
  configId: number;
}

export interface ProductList {
  price: string;
  imageUrl: string;
  link: ILink<Params>;
}

/**
 * 处理类目
 */
function setParentKeys(node: any): void {
  if (node.children && node.children.length > 0) {
    // 递归处理子节点
    node.children.forEach(setParentKeys);

    // 获取所有子节点的 key 并拼接成字符串
    const childKeys = node.children
      .map((child: any) => child.key)
      .filter((key: any) => key !== "")
      .join(",");

    // 设置当前节点的 key
    node.key = childKeys;
  }
}
/**
 * 获取类目树
 */
export const getCategoryTree = (categoryTreeData: ITabTree[]) => {
  const categoryTree = cloneDeep(categoryTreeData);
  categoryTree?.forEach(setParentKeys);
  return categoryTree;
};
