import React from "react";
import styles from "./index.module.scss";
import { Link } from "react-router-dom";

interface IDefaultInfoProps {
  imgSrc: string;
  title: string;
  btnText: string;
  btnLink: string;
}

function DefaultInfo(props: IDefaultInfoProps) {
  const { imgSrc, title, btnText, btnLink } = props;
  
  return (
    <div className={styles.container}>
      <img
        src={imgSrc}
        alt="404 error"
        style={{ width: '164px', height: '164px' }}
      />
      <h2>{title}</h2>
      <Link to={btnLink}>
        <div className={styles.btn}>{btnText}</div>
      </Link>
    </div>
  );
}

export default DefaultInfo;