import React from "react";
import { Radio, Space } from "antd-mobile";

import usePageTitle from "@/hooks/usePageTitle";
import Button from "@/common/Component/Button";
import { ILink } from "@/types/config";
import { useOpenUrl } from "@/hooks/useOpenUrl";
import styles from "./index.module.scss";
import ApplyLayout from "@/common/Component/ApplyPage";
import { useSearchParams } from "react-router-dom";

interface ISignContractProtocolProps {}

/**  商户签约协议 */
const SignContractProtocol: React.FC<ISignContractProtocolProps> = (props) => {
  const {} = props;
  usePageTitle({ title: "商户签约到店服务" });
  const [searchParams] = useSearchParams();
  const protocol = [
    "你好！我们诚挚邀请贵商家入驻案例本地生活，共同开拓广阔的市场机会，提升品牌曝光度，获得更多的顾客与销售机会。",
    "阿里本地生活作为一款聚焦于城市生活服务的应用，覆盖本地生活服务、餐饮、生活用品、娱乐及休闲等多个领域，致力于为用户提供便捷、高效的生活服务。",
    "请您在入驻前仔细阅读并充分理解以下条款内容，确保您同意并接受本条款后再进行操作。",
  ];
  const protocolLink: Array<{ title: string; link: ILink }> = [
    {
      title: "《用户服务协议》",
      link: {
        url: "",
      },
    },
    {
      title: "《隐私政策》",
      link: {
        url: "",
      },
    },
    {
      title: "《运营商协议》",
      link: {
        url: "",
      },
    },
  ];
  const openUrl = useOpenUrl();
  const [checked, setChecked] = React.useState(false);
  const changeChecked = (val: boolean) => {
    setChecked(val);
  };

  return (
    <ApplyLayout>
      <div className={styles.content}>
        <div className={styles.top}>
          <div className={styles.title}>商户签约到店服务</div>
          <Space block direction="vertical" className={styles.protocol}>
            <div className={styles.protocolTop}>尊敬的商户：</div>
            {protocol.map((item, index) => {
              return (
                <div className={styles.protocolItem} key={index}>
                  {item}
                </div>
              );
            })}
          </Space>
        </div>
        <div className={styles.bottom}>
          <div className={styles.checkProtocol}>
            <Radio
              className={styles.radio}
              checked={checked}
              onChange={changeChecked}
            >
              <span className={styles.radioLabel}>已阅读并同意</span>
            </Radio>
            {protocolLink.map((item, index) => {
              return (
                <span
                  key={index}
                  className={styles.protocolLink}
                  onClick={() => {
                    openUrl(item.link);
                  }}
                >
                  {item.title}
                </span>
              );
            })}
          </div>
        </div>
      </div>
      <div className={styles.footer}>
        <Button
          block
          color="primary"
          disabled={!checked}
          onClick={() => {
            openUrl({
              isRoute: true,
              url: "/open/arriveStore/merchant/info",
            });
          }}
        >
          下一步
        </Button>
      </div>
    </ApplyLayout>
  );
};

export default SignContractProtocol;
