import axios, { IBaseRes } from "@/common/Utils/APIClient";
import { AuditStatusEnum } from "../enum";

/**
 * 获取店铺详情
 */
export const getShopDetailElmShopNo = (
  params: IReq
): Promise<IBaseRes<Data>> => {
  return axios.get("/dmall_customer/tot/shop/detail", {
    param: params,
  });
};
interface IReq {
  /**
   * elm店铺编号
   */
  elmShopNo: string;
}
// auditStatus是审核状态

interface Data {
  attr: string;
  /**
   * 审核状态
   */
  auditStatus: AuditStatusEnum;
  businessLicense: string;
  cityName: string;
  districtName: string;
  elmShopNo: string;
  flexbleServingTimeStrList: string;
  geohash: string;
  gmtCreate: Date;
  gmtModified: Date;
  id: number;
  provinceName: string;
  shopAddress: string;
  shopAlipayAccount: string;
  shopAlipayOrders: string;
  shopCommission: number;
  shopContacts: string;
  shopDetailPics: string;
  shopFirstCatId: string;
  shopFirstCatName: string;
  shopLatitude: number;
  shopLongitude: number;
  shopMobile: string;
  shopName: string;
  shopPartner: number;
  shopPhones: string;
  shopProtocol: number;
  shopRate: number;
  shopSecondCatId: string;
  shopSecondCatName: string;
  shopSignPic: string;
  status: number;
}
