import { create } from "zustand";
import { persist, createJSONStorage } from "zustand/middleware";

interface IArriveStore {
  /**
   * 注册商家成功回调地址
   */
  registerStoreCallbackUrl?: string;
  /**
   * 设置注册商家成功回调地址
   */
  setRegisterStoreCallbackUrl: (url?: string) => void;
  /**
   * 注册商家，从elem跳转来带的商家id
   */
  registerShopId?: string;
  /**
   * 设置注册商家，从elem跳转来带的商家id
   */
  setRegisterShopId: (shopId: string) => void;
}

const useArriveStore = create<IArriveStore>()(
  persist(
    (set, get) => ({
      setRegisterStoreCallbackUrl: (url?: string) => {
        set({ registerStoreCallbackUrl: url });
      },
      setRegisterShopId: (shopId: string) => {
        set({ registerShopId: shopId });
      },
    }),
    {
      name: "arrive-store", // 存储中的项目名称，必须是唯一的
      storage: createJSONStorage(() => localStorage), // 使用localStorage作为存储
      partialize: (state) => ({
        // 仅存储特定的字段
        registerStoreCallbackUrl: state.registerStoreCallbackUrl,
      }),
    }
  )
);

export default useArriveStore;
