import axios from "@/common/Utils/APIClient";

export interface IGetFeedInfoPageReq {}
export interface IGetFeedInfoPageRes {
  success: boolean;
  errorMsg: string;
  errorCode: string;
  data: IGetFeedInfoPageResResult[];
}

export interface IGetFeedInfoPageResResult {
  content: string;
  contentType: string;
  coverImg: string;
  desc: string;
  feedType: string;
  modelData: ModelData;
  picUrl: string;
  source: string;
  title: string;
  uniqueId: string;
}

export interface ModelData {
  authorInfo: AuthorInfo;
  likeCount: number;
}

export interface AuthorInfo {
  avatar: string;
  name: string;
}

/**
 * 获取资讯banner列表
 * @param data
 * @returns
 */
export const getFeedBanner = (
  data: IGetFeedInfoPageReq
): Promise<IGetFeedInfoPageRes> => {
  return axios.get("/dmall_customer/ua/fdFeedInfo/banner", {
    param: {
      source: "wuhu",
    },
  });
};
