import React, { useCallback, useMemo, useState } from "react";
import style from "./index.module.scss";
import { Accounts } from "@/pages/Wallet/Services";
import { STATIC_HOST } from "@/common/Config/Constant";
import { EyeInvisibleOutline, EyeOutline } from "antd-mobile-icons";
import { Modal, SpinLoading } from "antd-mobile";
import clipboard from "copy-to-clipboard";
import { checkBalance } from "../../Services/checkBalance";
import { useRequest } from "ahooks";
import { formatCurrency } from "@/common/Utils/money";

interface IBankCardProps {
  data?: Accounts;
}

/**   */
const BankCard: React.FC<IBankCardProps> = (props) => {
  const { data } = props;
  const [visible, setVisible] = useState(false);
  const {
    run,
    data: moneyData,
    loading,
  } = useRequest(checkBalance, { manual: true });

  const onViewCardNum = useCallback((cardId: string) => {
    Modal.alert({
      showCloseButton: true,
      content: cardId,
      confirmText: "复制卡号",
      closeOnMaskClick: true,
      onConfirm: () => {
        clipboard(cardId);
      },
    });
  }, []);
  const onToggle = useCallback(
    (bankCardNo: string) => {
      if (!visible) {
        run({ bankCardNo: bankCardNo });
      }
      setVisible(!visible);
    },
    [visible]
  );
  const money = useMemo(() => {
    if (!visible) {
      return "*****";
    }
    if (visible && loading) {
      return <SpinLoading className={style.loading} />;
    }
    if (
      (typeof moneyData?.data === "number" ||
        typeof moneyData?.data === "string") &&
      !loading
    ) {
      const money = formatCurrency(moneyData?.data);
      return (
        <span>
          <span>￥</span>
          <span>{money}</span>
        </span>
      );
    }
    return "-";
  }, [moneyData, visible, loading]);
  const balanceNoText = useMemo(() => {
    if (data?.bankCardNo) {
      const start = data?.bankCardNo.slice(0, 4);
      const end = data?.bankCardNo.slice(-4);
      return `${start}******${end}`;
    }
    return "";
  }, [data]);
  return (
    <div
      className={style.content}
      style={{
        backgroundImage: `url('${STATIC_HOST}/589404262186d952.png')`,
      }}
    >
      <div className={style.info}>
        <div className={style.enterpriseName}>{data?.enterpriseName}</div>
        <div className={style.balanceNo}>
          <span className={style.balanceNoText}>{balanceNoText}</span>
          <span
            className={style.balanceNoAction}
            onClick={() => onViewCardNum(data?.bankCardNo || "")}
          >
            查看卡号
          </span>
        </div>
        <div className={style.top}>
          <div
            className={style.balanceName}
            onClick={() => onToggle(data?.bankCardNo || "")}
          >
            <span>可用金额(元)</span>
            {visible ? <EyeOutline /> : <EyeInvisibleOutline />}
          </div>
        </div>
        <div className={style.balance}>
          {visible ? (
            <div>
              <span>{money}</span>
            </div>
          ) : (
            <div>
              <span>*****</span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default BankCard;
