import Buttons from "@/common/Component/Button";
import VerificationCode, { IRef } from "@/common/Component/VerificationCode";
import { phoneRegExp } from "@/common/Config/regExp";
import { Form, Input, Toast } from "antd-mobile";
import React, { useEffect, useState } from "react";
import * as dd from "dingtalk-jsapi";

interface TestProps {}

const Test: React.FC<TestProps> = (props) => {
  const {} = props;
  const ref = React.useRef<IRef>({});
  const onFinish = () => {
    ref.current?.focus?.();
    setVisible(true);
  };
  const [form] = Form.useForm();
  const phone = Form.useWatch("phone", form);
  const [visible, setVisible] = useState(false);
  useEffect(() => {
    dd.ready(() => {
      dd.biz.navigation.hideBar({
        hidden: true,
        onSuccess: function (result: any) {
          // 导航栏隐藏后的操作
          Toast.show({
            icon: "success",
            content: "导航栏隐藏成功",
            duration: 1000,
          });
        },
        onFail: function (err: any) {
          Toast.show({
            icon: "fail",
            content: "导航栏隐藏失败",
            duration: 1000,
          });
        },
      });
    });
  }, []);
  return (
    <div>
      <div>
        <div>appType:{dd.env.appType}</div>
      </div>
      <Form layout="horizontal" form={form} onFinish={onFinish}>
        <Form.Item
          name="phone"
          rules={[
            { required: true, message: "请输入手机号" },
            { pattern: phoneRegExp, message: "请输入正确的手机号" },
          ]}
          label="手机号"
        >
          <Input />
        </Form.Item>
        <Form.Item>
          <Buttons block color="primary" type="submit">
            提交
          </Buttons>
        </Form.Item>
      </Form>
      <VerificationCode
        onClose={() => setVisible(false)}
        visible={visible}
        ref={ref}
        phone={phone}
      />
    </div>
  );
};

export default React.memo(Test);
