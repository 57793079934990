import Buttons from "@/common/Component/Button";
import Page from "@/common/Component/Page";
import { useOpenUrl } from "@/hooks/useOpenUrl";
import usePageTitle from "@/hooks/usePageTitle";
import React, { useEffect } from "react";
import styles from "./index.module.scss";
import { Card, Image } from "antd-mobile";
import useWalletStore from "@/store/wallet";
import { useShallow } from "zustand/react/shallow";
import { useSearchParams } from "react-router-dom";

interface IOpenPayProps {}

/** 开通芜湖支付  */
const OpenPayNew: React.FC<IOpenPayProps> = (props) => {
  usePageTitle({ title: "开通芜湖生活支付" });
  const openUrl = useOpenUrl();
  // bankCardAdd;
  const {} = props;
  const [setRedirect] = useWalletStore(
    useShallow((state) => [state.setRedirect])
  );
  const [params] = useSearchParams();

  useEffect(() => {
    const redirect = params.get("redirect");
    setRedirect(redirect || "");
  }, []);

  return (
    <Page className={styles.page}>
      <Image
        src="https://cdn.wuhuxianmai.cn/feed/1733304987266-f63f4b1513c626f19f211ccf4dbde6236114b07574a25bf3eee242e471025754"
        className={styles.image}
      />
      <Card className={styles.card} bodyClassName={styles.cardBody}>
        <Buttons
          fill="solid"
          color="primary"
          block
          onClick={() => openUrl({ url: "/bankCardAdd", isRoute: true })}
          className={styles.button}
        >
          单位已帮我开通，去绑定
        </Buttons>
      </Card>
    </Page>
  );
};

export default OpenPayNew;
