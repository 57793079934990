import React from "react";
import styles from "./index.module.scss";
import { Ellipsis, Image } from "antd-mobile";
import { useOpenUrl } from "@/hooks/useOpenUrl";
import { Result } from "../../services/fdFeedInfo";

interface IInfoCardProps {
  data: Result;
}

/** 资讯卡片  */
const InfoCard: React.FC<IInfoCardProps> = (props) => {
  const { data } = props;
  const openUrl = useOpenUrl();

  return (
    <div
      className={styles.infoCard}
      onClick={() => {
        if (data.contentType === "link") {
          openUrl({
            url: "/feedDetail",
            params: { id: data.uniqueId },
            isRoute: true,
          });
        }
      }}
    >
      <Image className={styles.image} fit="cover" src={data.picUrl} />
      <div className={styles.content}>
        <Ellipsis rows={2} content={data.title} className={styles.title} />
        <div className={styles.footer}>
          <Image
            className={styles.avatar}
            fit="cover"
            src={data?.modelData?.authorInfo?.avatar}
          />
          <Ellipsis
            rows={1}
            content={data?.modelData?.authorInfo?.name}
            className={styles.name}
          />
        </div>
      </div>
    </div>
  );
};

export default InfoCard;
