import { createBrowserRouter, Outlet, RouteObject } from "react-router-dom";
import React from "react";
import Layout from "@/layouts";
import Home from "../pages/Home";
import Pay from "../pages/Pay";
import Eleme from "../pages/Eleme";
import OneCard from "../pages/OneCard";
import PayResult from "../pages/PayResult";
import Wallet from "../pages/Wallet";
import NoMatch from "../pages/NoMatch";
import Coupon from "../pages/Coupon";
import BankCardDetail from "../pages/BankCardDetail";
import OrderHistory from "../pages/OrderHistory";
import InvoiceRecord from "../pages/InvoiceRecord";
import InvoiceTitle from "../pages/InvoiceTitle";
import DailyCoupon from "../pages/DailyCoupon";
import BankCardAdd from "../pages/BankCardAdd";
import Login from "../pages/Login";
import Logout from "../pages/Logout";
import Redirect from "../pages/Redirect";
import Promotion from "../pages/Promotion";
import FeedDetail from "../pages/FeedDetail";
import Mall from "../pages/Mall";
import Activity from "../pages/Mall/pages/ActivityDetail";
import ArriveStore from "../pages/ArriveStore";
import ActivityList from "../pages/Mall/pages/ActivityList";
import LocalLife from "@/pages/LocalLife";
import TabBarLayouts from "../layouts/TabBarLayout";
import My from "../pages/My";
import ApplyLayout from "../common/Component/ApplyPage";
import SignContractProtocol from "../pages/ArriveStore/pages/SignContractProtocol";
import SignContractInfo from "../pages/ArriveStore/pages/SignContractInfo";
import SignContractInfoStatus from "../pages/ArriveStore/pages/SignContractInfoStatus";
import EnterpriseApplay from "@/pages/Enterprise/pages/Applay";
import OpenPay from "../pages/OpenPay";
import InvestmentPromotion from "../pages/ArriveStore/pages/InvestmentPromotion";
import SignContractConfirm from "../pages/ArriveStore/pages/SignContractConfirm";
import { bindDDInfoLoader } from "./loader/bindDDInfo";
import OpenPayNew from "@/pages/OpenPayNew";
import Auth from "@/common/Component/Auth";
import UploadProtocol from "@/pages/ArriveStore/pages/UploadProtocol";
import ShopDetail from "@/pages/ArriveStore/pages/ShopDetail";
import ShopCollection from "@/pages/ArriveStore/pages/ShopCollection";
import DiscountAuthorization from "@/pages/ArriveStore/pages/DiscountAuthorization";
import EmployeeManagement from "@/pages/EmployeeManagement";
import AddEmployee from "@/pages/AddEmployee";
import StoreOrder from "@/pages/StoreOrder";
import MyBill from "@/pages/MyBill";
import BillDetail from "@/pages/BillDetail";
import PayOrder from "@/pages/PayOrder";
import PayOrderRule from "@/pages/PayOrderRule";
import TriggerPay from "@/pages/TriggerPay";
import { useDDJSAPIConfig } from "@/services/dd/ddJSAPIConfig";
import Test from "@/pages/Test";
import MyV2 from "@/pages/MyV2";
import PartnerCode from "@/pages/PartnerCode";

const Root = () => {
  useDDJSAPIConfig();

  return <Outlet />;
};
const routes: RouteObject[] = [
  {
    path: "/",
    element: (
      <Auth>
        <Root />
      </Auth>
    ),
    loader: () => bindDDInfoLoader(),
    children: [
      {
        element: <Layout />,
        children: [
          {
            index: true,
            element: <Home />,
          },
          {
            path: "coupon",
            element: <Coupon />,
          },
          {
            path: "eleme",
            element: <Eleme />,
          },
          {
            path: "pay",
            element: <Pay />,
          },
          {
            path: "payResult",
            element: <PayResult />,
          },
          {
            path: "wallet",
            element: <Wallet />,
          },
          {
            path: "openPay",
            // element: <OpenPay />,
            element: <OpenPayNew />,
          },
          {
            path: "oneCard",
            element: <OneCard />,
          },
          {
            path: "bankCardDetail",
            element: <BankCardDetail />,
          },
          {
            path: "orderHistory",
            element: <OrderHistory />,
          },

          {
            path: "invoiceTitle",
            element: <InvoiceTitle />,
          },
          {
            path: "invoiceRecord",
            element: <InvoiceRecord />,
          },
          {
            path: "dailyCoupon",
            element: <DailyCoupon />,
          },

          {
            path: "logout",
            element: <Logout />,
          },
          {
            path: "bankCardAdd",
            element: <BankCardAdd />,
          },
          {
            path: "feedDetail",
            element: <FeedDetail />,
          },

          {
            path: "promotion",
            element: <Promotion />,
          },
          {
            path: "mall",
            element: <Mall />,
          },
          {
            path: "mall/activity/detail",
            element: <Activity />,
          },
          {
            path: "mall/activity/list",
            element: <ActivityList />,
          },
          {
            path: "employeeManagement",
            element: <EmployeeManagement />,
          },
          {
            path: "addEmployee",
            element: <AddEmployee />,
          },
          {
            path: "storeOrder",
            element: <StoreOrder />,
          },
          {
            path: "myBill",
            element: <MyBill />,
          },
          {
            path: "billDetail",
            element: <BillDetail />,
          },
          {
            path: "triggerPay",
            element: <TriggerPay />,
          },
          {
            path: "payOrder",
            element: <PayOrder />,
          },
          {
            path: "payOrderRule",
            element: <PayOrderRule />,
          },
          {
            path: "arriveStore",
            children: [
              {
                path: "",
                element: <ArriveStore />,
                // element: <InvestmentPromotion />,
              },
              {
                path: "detail",
                element: <ShopDetail />,
              },
              // {
              //   path: "collection",
              //   element: <ShopCollection />,
              // },
              {
                path: "confirm",
                element: <DiscountAuthorization />,
              },
            ],
          },
          {
            path: "locallife",
            element: <LocalLife isH5={true} singlePage={true} />,
          },
          {
            path: "my",
            element: <MyV2 isH5 />,
          },
          {
            path: "*",
            element: <NoMatch />,
          },
        ],
      },
      {
        path: "/tabBar",
        element: <TabBarLayouts />,
        children: [
          {
            index: true,
            element: <LocalLife fullScrren={true} />,
          },

          {
            path: "arriveStore",
            element: <ArriveStore fullScrren={true} />,
            // element: <InvestmentPromotion />,
          },
          {
            path: "mall",
            element: <Mall fullScrren={true} />,
          },
          {
            path: "my",
            // element: <My />,
            element: <MyV2 />,
          },
        ],
      },
      {
        path: "/apply",
        children: [
          {
            path: "enterprise/apply",
            element: <EnterpriseApplay />,
          },
        ],
      },
    ],
  },
  {
    path: "/open",
    children: [
      {
        path: "arriveStore",
        children: [
          {
            path: "merchant/confirm",
            element: <SignContractConfirm />,
          },
          {
            path: "merchant/protocol",
            element: <SignContractProtocol />,
          },
          {
            path: "merchant/info",
            element: <SignContractInfo />,
          },
          {
            path: "merchant/upload/protocol",
            element: <UploadProtocol />,
          },
          {
            path: "merchant/status",
            element: <SignContractInfoStatus />,
          },
        ],
      },
    ],
  },
  {
    path: "/partnerCode/:code",
    element: <PartnerCode />,
  },
  {
    path: "login",
    element: <Login />,
  },
  {
    path: "test",
    element: <Test />,
  },
  {
    path: "redirect",
    element: <Redirect />,
  },

  {
    path: "*",
    element: <NoMatch />,
  },
];
export default createBrowserRouter(routes);
