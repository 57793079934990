import * as dd from "dingtalk-jsapi";

interface IRes {
  screenWidth: number;
  screenScale: number;
  SIMCard: boolean;
  netInfo: string;
  screenHeight: number;
  model: string;
  statusBarHeight: number;
  operatorType: string;
  brand: string;
  version: string;
}

interface IData {
  onSuccess?: (res: IRes) => void;
  onFail?: () => void;
}
/**
 * 获取手机基础信息
 * @param data
 */
export const getPhoneInfo = (data: IData) => {
  if (dd.env.platform !== "notInDingTalk") {
    dd.ready(() => {
      dd.device.base.getPhoneInfo(data);
    });
    return true;
  }
  return false;
};
