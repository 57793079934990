// useResume.ts
import { useEffect, useCallback } from 'react';

type ResumeCallback = () => void;

export const useResume = (callback: ResumeCallback) => {
  // 使用 useCallback 缓存回调函数，避免不必要的重新创建
  const memoizedCallback = useCallback(callback, [callback]);

  useEffect(() => {
    // 添加事件监听
    document.addEventListener('resume', memoizedCallback);

    // 清理函数
    return () => {
      document.removeEventListener('resume', memoizedCallback);
    };
  }, [memoizedCallback]);
};