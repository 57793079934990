import { getPhoneInfo } from "@/services/dd/getPhoneInfo";

/**
 * 获取状态栏高度
 * @returns Promise
 */
export const getStatusBarHeightPX = (): Promise<number> => {
  return new Promise((resolve, reject) => {
    const hasDD = getPhoneInfo({
      onSuccess: (res) => {
        console.log("getPhoneInfo", res);
        // 物理像素除以屏幕比例的缩放比
        resolve(res.statusBarHeight / res.screenScale);
      },
      onFail: reject,
    });
    if (!hasDD) {
      resolve(0);
    }
  });
};
