import React from "react";
import { List } from "antd-mobile";
import { RightOutline } from 'antd-mobile-icons'
import styles from "./index.module.scss";
import { formatCurrency } from "@/common/Utils/money";
import { useOpenUrl } from "@/hooks/useOpenUrl";


function OrderList({ orderList }: { orderList: any[] }) {
  const openUrl = useOpenUrl();

  return (
    <div className={styles.container}>
      {
        orderList.map((item: any, index: number) => (
          <List
            className={styles.orderList}
            mode="card"
            key={index}
          > 
            <List.Item
              prefix={
                <div className={styles.avatarByName}>{item.subject.slice(0, 1)}</div>
              }
              description={item.successTime}
              onClick={() => openUrl({
                isRoute: true,
                url: "/arriveStore/detail",
                params: {
                  id: item.shopId,
                }
              })}
              clickable={false}
            >
              <span className={styles.orderSubject}>{item.subject} </span>
              <RightOutline fontSize={14} className={styles.orderRightOutline} />
            </List.Item>
            <List.Item
              extra={
                <span>{formatCurrency(item.payAmount)}元</span>
              }
              className={styles.orderExtraItem}
            >
              {item.body && (
                <span className={styles.orderDiscount}>买单—{item.body}</span>
              )}
            </List.Item>
          </List>
        ))
      }
    </div>
  );
}

export default OrderList;