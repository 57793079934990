import axios from "@/common/Utils/APIClient";

interface ICheckBalanceReq {
  bankCardNo: string;
}
/**
 * 查询余额响应数据
 */
interface ICheckBalanceRes {
  /** 响应码 */
  code: string;
  /** 响应消息 */
  msg: string;
  /** 余额数据 */
  data: number;
  /** 是否成功 */
  success: boolean;
}
/**
 * 查询余额
 * @param params 查询参数
 * @returns Promise<ICheckBalanceRes>
 */

export const checkBalance = (
  params: ICheckBalanceReq
): Promise<ICheckBalanceRes> => {
  return axios.get("/dmall_account/Account/balance", { param: params });
};
