import axios from "@/common/Utils/APIClient";
import { GrantEnum } from "@/services/user/getUserInfo";

interface IParams {
  authCode: string;
  corpId: string;
}
export interface IConfirmRes {
  success: boolean;
  msg: string;
  data: IConfirmResData;
}

export interface IConfirmResData {
  staffName?: string;
  staffPhone?: string;
  alipayEmployeeId?: string;
  /**
   * 支付宝签到地址
   */
  signUrl?: string;
  /**
   * 是否授权,0 未授权，1 已授权
   */
  isGrant?: GrantEnum;
  isPush?: number;
}

export const getConfirm = (params: IParams) => {
  return axios
    .get("/dmall_customer/staff/confirm", { param: params })
    .then((res: IConfirmRes) => res?.data);
};
