import axios, { IBaseRes } from "@/common/Utils/APIClient";
import { useRequest } from "ahooks";
import { useMemo } from "react";

interface ICate {
  name: string;
  id: number;
  level: number;
  children?: ICate[];
}
interface IRecord {
  label: string;
  value: number;
  children?: IRecord[];
  data?: ICate;
  isLeaf?: boolean;
}
/**
 * 获取商品分类树
 */
const getCateTree = (): Promise<IBaseRes<ICate[]>> => {
  return axios.get("/dmall_customer/tot/cats");
};

export const findTree = (
  data: IRecord[],
  values: number[]
): { label: string; value: number }[] => {
  const resArr: { label: string; value: number }[] = [];
  values.reduce((pre, cur, curIndex, arr) => {
    const findItem = pre.find((item) => item.value === cur);
    if (findItem) {
      resArr.push({ label: findItem.label, value: findItem.value });
    }
    return findItem?.children || [];
  }, data);
  return resArr;
};
const formatTree = (tree: ICate[]) => {
  return tree.map((item) => {
    const data: IRecord = {
      label: item.name,
      value: item.id,
      data: item,
    };
    if (item?.children?.length) {
      data.isLeaf = false;
      data.children = formatTree(item.children);
    } else {
      data.isLeaf = true;
    }

    return data;
  });
};
/**
 * 获取分类树
 */
export const useCateTree = () => {
  const { data, loading } = useRequest(getCateTree);
  const selectTree = useMemo(() => {
    const tree = data?.data || [];
    return formatTree(tree);
  }, [data]);
  return {
    selectTree,
    loading,
    data,
  };
};
