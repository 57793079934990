import * as dd from "dingtalk-jsapi";
import { useEffect } from "react";
const showDingRight = process.env.REACT_APP_SHOW_DING_RIGHT;

export const useHideDingRight = () => {
  useEffect(() => {
    if (dd?.env?.platform !== "notInDingTalk" && !showDingRight) {
      dd.ready(() => {
        setHideDingRight();
      });
    }
  }, []);
};

export const setHideDingRight = () => {
  dd.biz.navigation.setRight({
    show: false, // 设置为 false 禁用分享按钮
    control: true, // 控制显示隐藏
    onSuccess: function () {
      console.log("禁用分享按钮成功");
    },
  });
};
