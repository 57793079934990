import axios, { IBaseRes } from "@/common/Utils/APIClient";

/**
 * 商家更新类目
 */
export const shopUpdateCat = (params: IReq): Promise<IBaseRes<boolean>> => {
  return axios.post("/dmall_customer/tot/shop/updateCat", params);
};
interface IReq {
  /**
   * 店铺id
   */
  elmShopNo: string;
  shopFirstCatId: number; // 一级类目
  shopFirstCatName: string; // 一级类目名
  shopSecondCatId: number; // 二级类目名
  shopSecondCatName: string; // 二级类目名
}
