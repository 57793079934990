import React, { useEffect, useState } from "react";
import styles from "./index.module.scss";
import { NavBar, Toast } from "antd-mobile";
import { CloseOutline, LeftOutline } from "antd-mobile-icons";
import { useNavigate } from "react-router-dom";
import { navigationClose } from "@/services/dd/navgationClose";
import { getStatusBarHeightPX } from "@/common/Utils/statusBar";

interface HeaderProps {
  title?: string;
  hideTitle?: boolean;
  style?: React.CSSProperties;
}

const PageHeader: React.FC<HeaderProps> = (props) => {
  const { title, hideTitle, style } = props;
  const navigate = useNavigate();
  const [paddingTop, setPaddingTop] = useState(0);
  useEffect(() => {
    getStatusBarHeightPX().then((res) => {
      setPaddingTop(res);
      document.documentElement.style.setProperty(
        "--header-padding-top",
        `${res}px`
      );
    });
  }, []);

  const back = () => {
    navigate(-1);
  };
  const close = () => {
    const hasDD = navigationClose({
      onFail: () => {
        Toast.show({
          icon: "fail",
          content: "关闭失败",
        });
      },
    });
    if (!hasDD) {
      window.self.close();
    }
  };
  return (
    <div className={styles.header} style={{ paddingTop, ...(style || {}) }}>
      {!hideTitle && (
        <NavBar
          backIcon={null}
          // onBack={back}
          // left=""
          right={<CloseOutline className={styles.closeIcon} onClick={() => close()} />}
          className={styles.nav}
        >
          <span className={styles.title}>{title}</span>
        </NavBar>
      )}
    </div>
  );
};

export default React.memo(PageHeader);
