import React, { useEffect, useMemo } from "react";
import styles from "./index.module.scss";
import { Image, Toast } from "antd-mobile";
import ApplyLayout from "@/common/Component/ApplyPage";
import { useRequest } from "ahooks";
import { getShopDetailElmShopNo } from "../../service/getShopDetailElmShopNo";
import { useSearchParams } from "react-router-dom";
import { AuditStatusEnum } from "../../enum";
import PageLoading from "@/common/Component/PageLoading";
import Buttons from "@/common/Component/Button";
import { useOpenUrl } from "@/hooks/useOpenUrl";

interface ISignContractInfoStatusProps {}

/**  商户签约协议-结果 */
const SignContractInfoStatus: React.FC<ISignContractInfoStatusProps> = (
  props
) => {
  const {} = props;
  const [searchParams] = useSearchParams();
  const shopId = searchParams.get("shopId");
  const {
    run: getShopDetailRun,
    loading,
    data,
  } = useRequest(getShopDetailElmShopNo, {
    manual: true,
  });
  const openUrl = useOpenUrl();
  useEffect(() => {
    if (shopId) {
      getShopDetailRun({
        elmShopNo: shopId,
      });
    } else {
      Toast.show("店铺不存在");
    }
  }, []);
  const { imageUrl, hasAgain, title, text } = useMemo(() => {
    const obj = {
      text: "",
      imageUrl: "",
      hasAgain: false,
      title: "",
    };
    if (data?.data?.auditStatus === AuditStatusEnum.AUDIT_NO_PASS) {
      obj.hasAgain = true;
      obj.title = "审核未通过";
      obj.text = data.data.attr;

      obj.imageUrl =
        "https://cdn.wuhuxianmai.cn/feed/1732505912398-0da006d19473604a7aec9f6008dd5d27dab3f8b1b663305bb63587e8d67e06a2";
    }
    if (data?.data?.auditStatus === AuditStatusEnum.WAIT) {
      obj.title = "审核中";
      obj.text = "您的入驻申请已提交，工作人员会在2个工作日内审核，请耐心等待";
      obj.imageUrl =
        "https://cdn.wuhuxianmai.cn/feed/1732505915295-1312d12f901e9afdb22b56de3c8f9b0ee304eb47c9e3605b4404a427062c1a14";
    }

    obj.title = "审核已通过";
    obj.hasAgain = true;

    obj.text = "您将在72小时内收到来自支付宝企业码的授权信息，请注意查收";
    obj.imageUrl =
      "https://cdn.wuhuxianmai.cn/feed/1732505917991-f546ad46b439443e2944d0515a2b7e391433930e40140966bd804308985401a0";
    return obj;
  }, [data]);
  return (
    <PageLoading loading={loading}>
      <ApplyLayout>
        <div className={styles.content}>
          <Image src={imageUrl} className={styles.icon} />
          <div className={styles.title}>{title}</div>
          <div className={styles.subTitle}>{text}</div>
          {hasAgain && (
            <Buttons
              color="primary"
              onClick={() => {
                openUrl({
                  url: "/arriveStore/merchant/confirm",
                  isRoute: true,
                  params: { shopId },
                });
              }}
            >
              重新填写
            </Buttons>
          )}
        </div>
      </ApplyLayout>
    </PageLoading>
  );
};

export default SignContractInfoStatus;
