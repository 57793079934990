import axios, { IBaseRes } from "@/common/Utils/APIClient";

interface IReq {
  shopId: string;
}
export const getFromElmShopElmDetail = (
  param: IReq
): Promise<IBaseRes<Data>> => {
  return axios.get("/dmall_customer/elm/form", {
    param,
  });
};

interface Data {
  attr: string;
  auditStatus: number;
  businessLicense: string;
  cityName: string;
  districtName: string;
  elmShopNo: string;
  flexbleServingTimeStrList: string;
  geohash: string;
  gmtCreate: Date;
  gmtModified: Date;
  id: number;
  provinceName: string;
  shopAddress: string;
  shopAlipayAccount: string;
  shopAlipayOrders: string;
  shopCommission: number;
  shopContacts: string;
  shopDetailPics: string;
  shopFirstCatId: string;
  shopFirstCatName: string;
  shopLatitude: number;
  shopLongitude: number;
  shopMobile: string;
  shopName: string;
  shopPartner: number;
  shopPhones: string;
  shopProtocol: number;
  shopRate: number;
  shopSecondCatId: string;
  shopSecondCatName: string;
  shopSignPic: string;
  status: number;
}
