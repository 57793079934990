import ApplyLayout from "@/common/Component/ApplyPage";
import React, { useState, useEffect } from "react";
import { formatCurrency } from "@/common/Utils/money";
import { Form, Input, NumberKeyboard, Toast } from "antd-mobile";
import { QuestionCircleOutline } from "antd-mobile-icons";
import Buttons from "@/common/Component/Button";
import { useOpenUrl } from "@/hooks/useOpenUrl";
import { place4shop, getShopInfo } from "./Services";
import { useRequest } from "ahooks";
import Decimal from "decimal.js";
import { useSearchParams } from "react-router-dom";

import styles from "./index.module.scss";
import { pxToRem } from "@/common/Utils/rem";

function PayOrder() {
  const [form] = Form.useForm();
  const openUrl = useOpenUrl();
  const [params] = useSearchParams();
  const shopId = params.get('id');
  const [visible, setVisible] = useState(false);
  const [payTotalMoney, setPayTotalMoney] = useState("");
  const [noDiscountMoney, setNoDiscountMoney] = useState("");
  const [payType, setPayType] = useState("");
  const [payFinalMoney, setPayFinalMoney] = useState("");
  const [showCursor, setShowCursor] = useState(false);
  const [btnDisabled, setBtnDisabled] = useState(false);

  if (!shopId) {
    Toast.show({
      content: '缺少商户ID',
      afterClose: () => {
        window.history.back();
      }
    });
  }

  const { data: shopInfo } = useRequest(
    () => shopId ? getShopInfo({ shopId }) : Promise.reject('No shopId'),
    { ready: !!shopId }
  );

  const { run } = useRequest(place4shop, {
    manual: true,
    onSuccess: (res: any) => {
      if (res.success) {
        openUrl({
          isRoute: true,
          url: "/pay",
          params: {
            payOrderId: res.data,
          }
        })
      }
    },
    onError: (err: any) => {
      Toast.show({
        content: err?.msg || '支付失败'
      })
    }
  });

  useEffect(() => {
    if (!shopId) {
      Toast.show({
        content: '缺少商户ID',
        afterClose: () => {
          window.history.back();
        }
      });
    }
  }, [shopId]);

  const submit = () => {
    const payTotalMoneyNum = parseMoney(payTotalMoney);
    const noDiscountMoneyNum = parseMoney(noDiscountMoney);

    if (!payFinalMoney) {
      Toast.show("请输入消费总额");
      return;
    }
    if (noDiscountMoneyNum > payTotalMoneyNum) {
      Toast.show("消费总额需大于不参与优惠金额");
      return;
    }

    run({
      shopId: shopId || "",
      buyAmount: payTotalMoneyNum,
      noDiscountAmount: noDiscountMoneyNum,
    });
  };

  const normalize = (value: string) => {
    if (value === ".") {
      value =  "0.";
    }
    const valueStr = value?.replaceAll("¥", "");
    if (valueStr === "") {
      return "";
    }

    return valueStr ? "¥" + valueStr : "";
  };

  const goRule = () => {
    openUrl({
      url: "/payOrderRule",
      isRoute: true,
    });
  };

  const parseMoney = (value: string) => {
    const result = value?.replace("¥", "") ? value?.replace("¥", "") : 0;
    return new Decimal(result).mul(100).toNumber();
  };

  useEffect(() => {
    if (payTotalMoney) {
      const payTotalMoneyNum = parseMoney(payTotalMoney);
      const noDiscountMoneyNum = parseMoney(noDiscountMoney);

      if (payTotalMoneyNum < noDiscountMoneyNum) {
        setPayFinalMoney(formatCurrency(payTotalMoneyNum) ?? "");
        setBtnDisabled(true);
        return ;
      }
      setBtnDisabled(false);
      const payFinalMoneyNum = new Decimal(payTotalMoneyNum)
        .sub(noDiscountMoneyNum)
        .mul(shopInfo.shopDiscountDto.discountRate)
        .round()
        .add(noDiscountMoneyNum)
        .toNumber();
      
      setPayFinalMoney(formatCurrency(payFinalMoneyNum) ?? "");
    } else {
      setPayFinalMoney("");
    }
  }, [payTotalMoney, noDiscountMoney]);

  
  const canBeInput = (prev: string, value: string) => {
    let result = true;
    // 一个小数点直接通过，后面会转换
    if (value === ".") {
      return true;
    }

    // 只能有1个小数点
    if (prev.includes('.') && value === ".") {
      result = false;
    }
    // 小数点后最多2位
    if (prev.includes('.') && prev.split('.')[1]?.length >= 2) {
      result = false;
    }

    // 最大不超过99999.99
    if (parseMoney(prev + value) > 10000000) {
      result = false;
    }
    return result;
  }

  const onInput = (value: string) => {
    if (payType === "payTotalMoney") {
      setPayTotalMoney((prev) => {
        if (!canBeInput(prev, value)) {
          return prev;
        }
        const valueStr = normalize(prev + value);
        return valueStr;
      });
    } else if (payType === "noDiscountMoney") {
      setNoDiscountMoney((prev) => { 
        if (!canBeInput(prev, value)) {
          return prev;
        }
        const valueStr = normalize(prev + value);
        return valueStr;
      });
    }
  };

  const onDelete = () => {
    if (payType === "payTotalMoney") {
      setPayTotalMoney((prev) => normalize(prev.slice(0, -1)));
    } else if (payType === "noDiscountMoney") {
      setNoDiscountMoney((prev) => normalize(prev.slice(0, -1)));
    }
  };

  if (!shopId || !shopInfo) {
    return null;
  }

  return (
    <ApplyLayout>
      <div>
        <div className={styles.card}>
          <div className={styles.header}>
            <div className={styles.title}>{shopInfo.shopName}</div>
            <div className={styles.subTitle}>{shopInfo.shopInfoDto.shopAddress} </div>
          </div>
          <div className={styles.discountLine} onClick={() => goRule()}>
            <div className={styles.discount}>
              <span>企业员工专享价：{new Decimal(shopInfo.shopDiscountDto.discountRate).times(10).toFixed(1)}折</span>
              <QuestionCircleOutline className={styles.helpIcon} />
            </div>
          </div>
          <div>
            <Form form={form} layout="horizontal" className={styles.form}>
              <Form.Item
                className={styles.totalMoney}
                label="消费总额"
                name="payMoney"
              >
                <div
                  onClick={() => {
                    setPayType("payTotalMoney");
                    setVisible(true);
                    setShowCursor(true);
                  }}
                >
                  <Input
                    placeholder="请询问服务员后输入"
                    className={styles.input}
                    value={payTotalMoney}
                    readOnly
                  />
                  {showCursor && payType === "payTotalMoney" && <span className={styles.cursor} />}
                </div>
              </Form.Item>
              <Form.Item
                className={styles.noDiscount}
                label="不参与优惠金额"
                name="payMoney1"
              >
                <div
                  onClick={() => {
                    setPayType("noDiscountMoney");
                    setVisible(true);
                    setShowCursor(true);
                  }}
                >
                  <Input
                    className={styles.input}
                    placeholder="请询问服务员后输入"
                    value={noDiscountMoney}
                    readOnly
                  />
                  {
                    showCursor && payType === "noDiscountMoney" && 
                      <span className={styles.cursor} style={{ backgroundColor: '#1d2129', height: pxToRem(30) }} />}
                </div>
              </Form.Item>
            </Form>
          </div>
        </div>
        <div className={styles.footer}>
          <Buttons 
            block 
            color="primary" 
            onClick={submit}
            className={!payFinalMoney || btnDisabled ? styles.disabledBtn : ''}
          >
            确认买单 {payFinalMoney &&  "¥" + payFinalMoney}
          </Buttons>
          <div className={styles.footerMsg}>
            买单金额 =（消费总额-不参与优惠金额）*员工专享价+不参与优惠金额
          </div>
        </div>
      </div>
      <NumberKeyboard
        visible={visible}
        onInput={onInput}
        onDelete={onDelete}
        onClose={() => {
          setVisible(false);
          setShowCursor(false);
        }}
        confirmText='确定'
        customKey='.'
      />
    </ApplyLayout>
  );
}

export default PayOrder;
