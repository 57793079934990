import React from "react";
import { create } from "zustand";
import { persist, createJSONStorage } from "zustand/middleware";

interface IArriveStore {
  hideTitle?: boolean;
  setHideTitle: (v: boolean) => void;
  title?: string;
  setTitle: (v?: string) => void;
  hideHeader?: boolean;
  setHideHeader: (v: boolean) => void;
  style?: React.CSSProperties;
  setStyle: (v?: React.CSSProperties) => void;
}

const usePageHeader = create<IArriveStore>()(
  persist(
    (set, get) => ({
      hasShowContent: true,
      setHideTitle: (v: boolean) => {
        set({ hideTitle: v });
      },
      setTitle: (v?: string) => {
        set({ title: v });
      },
      setHideHeader: (v: boolean) => {
        set({ hideHeader: v });
      },
      setStyle: (v?: React.CSSProperties) => {
        set({ style: v });
      },
    }),
    {
      name: "header-store", // 存储中的项目名称，必须是唯一的
      storage: createJSONStorage(() => localStorage), // 使用localStorage作为存储
      partialize: (state) => ({
        // 仅存储特定的字段
      }),
    }
  )
);

export default usePageHeader;
