import axios from "@/common/Utils/APIClient";

interface IBindDDInfoReq {
  corpId: string;
  route?: string;
  clientId: string;
  redirectUrl?: string;
}
/**
 * 绑定钉钉信息
 */
export const bindDDInfo = (data: IBindDDInfoReq): Promise<IBindDDInfoRes> => {
  return axios.get("/dmall_customer/ding/dd/token", { param: data });
};
interface IBindDDInfoRes {
  success: boolean;
  msg: string;
  errorCode: string;
  code: string;
  data: IData;
}
export interface IData {
  isExpires: boolean;
  url: string;
}
