import apiClient from "src/common/Utils/APIClient";
interface IMaterialReq {}
export interface Data {
  id: number;
  gmtCreate: Date;
  gmtModified: Date;
  keycode: string;
  type: null;
  title: string;
  description: string;
  status: number;
  value: string;
}

/**
 * 获取后台json配置内容
 */
export const getConfigDetail = (params?: IMaterialReq): Promise<Data> => {
  return apiClient
    .get("/dmall_customer/config/detail", { param: params })
    .then((res: Data) => {
      return res;
    });
};
