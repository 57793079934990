import React from "react";
import { Outlet } from "react-router-dom";
import { SafeArea } from "antd-mobile";
import styles from "./index.module.scss";

interface IApplyLayoutProps {
  children: React.ReactNode;
}

/** 申请页面布局  */
const ApplyLayout: React.FC<IApplyLayoutProps> = (props) => {
  const { children } = props;

  return (
    <div className={styles.layout}>
      <SafeArea position="top" />
      <div className={styles.content}>{children}</div>
      <SafeArea position="bottom" />
    </div>
  );
};

export default ApplyLayout;
