import {
  Ellipsis,
  Grid,
  Image,
  InfiniteScroll,
  SpinLoading,
  Swiper,
  Toast,
} from "antd-mobile";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import classNames from "classnames";
import { useRequest } from "ahooks";

import { PageItem } from "src/common/Component/Page";
import Tabs, { ITabsItemData } from "src/common/Component/Tabs";
import Search from "src/common/Component/Search";

import StoreCard from "./components/StoreCard";
import { bannerList, getDiscount } from "./static";
import usePageTitle from "src/hooks/usePageTitle";
import styles from "./index.module.scss";
import useUserStore from "@/store/global";
import { GrantEnum } from "@/services/user/getUserInfo";
import {
  openAuthDialog,
  userDingDingAuthFieldScope,
  userDingDingAuthRpcScope,
} from "@/services/dd/openAuth";
import { getConfirm } from "@/services/user/getConfirm";
import { useShallow } from "zustand/react/shallow";
import { shopPageQuery, ShopRecord } from "./service/shopPageQuery";
import { useOpenUrl } from "@/hooks/useOpenUrl";
import { getLocation } from "@/services/dd/getLocation";
import { useCateTree } from "./service/getCateTreeNew";
import usePageHeader from "@/store/pageHeader";

interface IArriveStoreProps {
  fullScrren?: boolean;
}

const pageSize = 20;

/** 到店-首页  */
const ArriveStore: React.FC<IArriveStoreProps> = (props) => {
  const { fullScrren } = props;
  usePageTitle({ title: "到店" });
  const [dingdingUserInfo, corpId, clientId] = useUserStore(
    useShallow((state) => [
      state.dingdingUserInfo,
      state.corpId,
      state.clientId,
    ])
  );
  const { getDingDingUserInfo } = useUserStore();
  const [activeKey, setActiveKey] = useState<string>("1");
  const { data: addressData } = useRequest(getLocation);
  console.log("address", addressData);
  const [queryStr, setQueryStr] = useState("");
  // 是否吸顶
  const [hasToTop, setHasToTop] = useState(false);
  const tabRef = useRef<HTMLDivElement>(null);
  const pageNo = useRef(0);
  const hasInit = useRef(true);
  const [allMapData, setAllMapData] = useState<ShopRecord[]>([]);
  const [hasError, setError] = useState(false);
  const openUrl = useOpenUrl();
  const { data: tabData } = useCateTree();
  const {
    data: pageData,
    run: shopPageQueryRun,
    loading: materialLoading,
    cancel: cancelMaterialRun,
  } = useRequest(shopPageQuery, {
    manual: true,
    onSuccess: (res, params) => {
      pageNo.current = params[0].pageNum;
      const list = res?.data?.records || [];
      setAllMapData([...allMapData, ...list]);
      setError(false);
    },
    onError: () => {
      setError(true);
      console.log("error");
    },
  });
  const items = bannerList.map((item, index) => (
    <Swiper.Item key={index}>
      <Image
        src={item.imgUrl}
        fit="cover"
        lazy
        width={"100%"}
        height={"100%"}
        className={styles.swiperItem}
        onClick={() => {
          if (item.linkUrl) window.location.href = item.linkUrl;
        }}
      />
    </Swiper.Item>
  ));

  /**
   *  一级类目切换
   */
  const changeTab = (key: string) => {
    hasInit.current = true;
    pageNo.current = 0;
    cancelMaterialRun();
    setAllMapData([]);
    setActiveKey(key);
    shopPageQueryRun({
      pageNum: pageNo.current + 1,
      pageSize,
      cityName: addressData?.city,
      firstCatId: key,
      shopName: queryStr,
    });
  };

  /** 加载更多 */
  async function loadMore() {
    if (materialLoading || hasError) {
      return;
    }
    if (hasInit.current) {
      hasInit.current = false;
    }
    shopPageQueryRun({
      pageNum: pageNo.current + 1,
      pageSize,
      cityName: addressData?.city,
      firstCatId: activeKey,
      shopName: queryStr,
    });
  }

  const hasMore = useMemo(() => {
    const total = pageData?.data?.total || 0;
    if (hasInit.current) {
      hasInit.current = false;
      return true;
    }

    return total > allMapData.length;
  }, [pageData, allMapData]);

  const showData = useMemo(() => {
    const data: Array<typeof allMapData> = [[], []];
    allMapData.forEach((item, index) => {
      data[index % 2].push(item);
    });
    return data;
  }, [allMapData]);

  /**
   * 搜索
   */
  const onSearch = (value: string) => {
    hasInit.current = true;
    pageNo.current = 0;
    cancelMaterialRun();
    setAllMapData([]);
    shopPageQueryRun({
      pageNum: pageNo.current + 1,
      pageSize,
      cityName: addressData?.city,
      firstCatId: activeKey,
      shopName: value,
    });
  };
  useEffect(() => {
    if (!tabRef.current) {
      return;
    }
    // tab父元素绑定吸顶事件

    const onScroll = (e: Event) => {
      console.log("eeee", tabRef.current?.offsetTop);
      const elementOffsetTop = tabRef.current?.offsetTop || 0;
      const parentScrollTop = tabRef.current?.parentElement?.scrollTop || 0;
      setHasToTop(elementOffsetTop <= parentScrollTop);
    };
    tabRef.current.parentElement?.addEventListener("scroll", onScroll);
    return () => {
      tabRef.current?.parentElement?.removeEventListener("scroll", onScroll);
    };
  }, []);
  useEffect(() => {
    if (typeof addressData?.success === "boolean") {
      shopPageQueryRun({
        pageNum: pageNo.current + 1,
        pageSize,
        cityName: addressData?.city,
        firstCatId: "",
        shopName: "",
      });
    }
  }, [addressData]);

  const openAuth = useCallback(async (corpId: string, clientId: string) => {
    const data = await openAuthDialog({
      corpId,
      type: 0,
      rpcScope: userDingDingAuthRpcScope.ContactUserRead,
      fieldScope: userDingDingAuthFieldScope.ContactUserMobile,
      clientId,
    });
    if (data.success && data.authCode) {
      return await getConfirm({ authCode: data.authCode, corpId });
    } else {
      Toast.show({
        icon: "fail",
        content: data.message || "授权失败",
      });
    }
  }, []);
  useEffect(() => {
    if (
      dingdingUserInfo &&
      dingdingUserInfo?.isGrant === GrantEnum.False &&
      corpId &&
      clientId
    ) {
      openAuth(corpId, clientId).then((res) => {
        if (res?.isGrant === GrantEnum.True) {
          Toast.show({
            icon: "success",
            content: "授权成功",
          });
          getDingDingUserInfo({ corpId });
        }
      });
    }
  }, [corpId, dingdingUserInfo]);
  const address = useMemo(() => {
    return addressData?.city;
  }, [addressData]);
  const tabDataSource = useMemo(() => {
    const tabDataS: ITabsItemData[] = [
      {
        key: "",
        title: "全部",
      },
    ];
    setActiveKey("");
    tabData?.data
      ?.filter((item) => item.categoryName)
      ?.forEach((item) => {
        tabDataS.push({
          title: item.categoryName,
          key: item.id.toString(),
        });
      });
    return tabDataS;
  }, [tabData]);

  // useEffect(() => {
  //   if (fullScrren) {
  //     setHideTitle(true);
  //   }
  //   return () => {
  //     setHideTitle(false);
  //   };
  // }, []);
  return (
    <>
      <PageItem className={styles.firstPageItem}>
        {address && (
          <div className={styles.address}>
            <Ellipsis content={address} />
          </div>
        )}
        <Search
          clearable
          onChange={setQueryStr}
          placeholder="烧烤"
          className={styles.input}
          onBlur={(e) => {
            const value = e.currentTarget.value;
            onSearch(value);
          }}
        />
      </PageItem>
      {/* <PageItem className={styles.swiperPageItem}>
        <Swiper
          className={classNames(styles.swiper, {
            [styles.hideIndicator]: items.length === 1,
          })}
          autoplay
          loop
        >
          {items}
        </Swiper>
      </PageItem> */}
      <div
        className={classNames(styles.tabContainer, {
          [styles.tabToTop]: hasToTop,
          [styles.tabContainerScreen]: fullScrren,
        })}
        ref={tabRef}
      >
        <Tabs
          activeKey={activeKey}
          model="out"
          itemData={tabDataSource}
          className={styles.tabs}
          onChange={changeTab}
          tabTitleClassName={styles.tabTitle}
          activeTitleClassName={styles.activeTabTitle}
        />
      </div>
      <PageItem className={styles.content}>
        {!!allMapData?.length && (
          <>
            <Grid columns={2} className={styles.productGrid}>
              {showData.map((item, index) => {
                return (
                  <Grid.Item key={index}>
                    <Grid columns={1}>
                      {item.map((li, iIndex: number) => {
                        return (
                          <Grid.Item
                            key={iIndex}
                            className={styles.productGridItem}
                          >
                            <StoreCard
                              coverUrl={li.doorFrontPic}
                              title={li.shopName}
                              tag={`${getDiscount(li.discountRate)}折`}
                              onClick={() => {
                                openUrl({
                                  url: "/arriveStore/detail",
                                  isRoute: true,
                                  params: {
                                    id: li.shopId,
                                  },
                                });
                              }}
                            />
                          </Grid.Item>
                        );
                      })}
                    </Grid>
                  </Grid.Item>
                );
              })}
            </Grid>
            <InfiniteScroll loadMore={loadMore} hasMore={hasMore} />
          </>
        )}
        {!materialLoading && !allMapData.length && (
          <div className={styles.empty}>暂无数据</div>
        )}
        {materialLoading && !allMapData.length && (
          <div className={styles.loading}>
            <SpinLoading />
          </div>
        )}
      </PageItem>
    </>
  );
};

export default ArriveStore;
