import axios from "@/common/Utils/APIClient";
interface IParams {
  phone: string;
  code: string;
}

interface ICheckVerificationCodeRes {
  success: boolean;
  msg: string;
  data: boolean;
}

/**
 * 检查短信验证码
 */
export const checkVerificationCode = (
  params: IParams
): Promise<ICheckVerificationCodeRes> => {
  return axios.post(
    `/dmall_auth/ua/captcha/sms/${params?.phone}/${params?.code}`
  );
};
