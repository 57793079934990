import * as dd from "dingtalk-jsapi";

interface IData {
  onSuccess?: () => void;
  onFail?: () => void;
}
/**
 * 钉钉关闭当前页面
 * @param data
 */
export const navigationClose = (data: IData) => {
  if (dd.env.platform !== "notInDingTalk") {
    dd.ready(() => {
      dd.biz.navigation.close(data);
    });
    return true;
  } else {
    return false;
  }
};
