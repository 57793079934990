import React from "react";
import { SpinLoading } from "antd-mobile";
import styles from "./index.module.scss";
import classNames from "classnames";

interface IProps {
  children?: React.ReactNode;
  loading?: boolean;
  className?: string;
  fullScreen?: boolean;
}
function PageLoading(props: IProps) {
  const { fullScreen = true, loading, children, className } = props;
  return (
    <div className={classNames(styles.loading, className)}>
      {loading && (
        <div
          className={classNames(styles.spin, {
            [styles.fullScreen]: fullScreen,
          })}
        >
          <SpinLoading color="default" style={{ "--size": "32px" }} />
        </div>
      )}
      {children}
    </div>
  );
}

export default PageLoading;
