import React, { useEffect, useRef, useState } from "react";
import { useRequest } from "ahooks";
import { IndexBar, List, SwipeAction } from "antd-mobile";
import { SwipeActionRef } from 'antd-mobile/es/components/swipe-action'
import * as dd from "dingtalk-jsapi";
import Search from "@/common/Component/Search";
import usePageTitle from "@/hooks/usePageTitle";
import { useOpenUrl } from "@/hooks/useOpenUrl";
import DefaultInfo from "@/common/Component/DefaultInfo";
import { deleteEmployee, getEmployeeList } from "./Services";
import { setHideDingRight } from "@/hooks/useHideDingRight";

import styles from "./index.module.scss";

interface StaffMember {
  staffName: string;
  staffPhone: string;
  id: string;
}

interface Group {
  groupName: string;
  enterpriseStaffList: StaffMember[];
}

function EmployeeManagement() {
  const hasEmployee = false;
  usePageTitle({ title: "员工管理" });
  const openUrl = useOpenUrl();
  const contentRef = useRef<HTMLDivElement>(null);
  const swipeActionRef = useRef<SwipeActionRef>(null)
  const [topDistance, setTopDistance] = useState(0);
  const [groups, setGroups] = useState<Group[]>([]);

  const { run: getEmployeeListRun } = useRequest(getEmployeeList, {
    manual: true,
    onSuccess: (res) => {
      const { success, data } = res;
      if (success) {
        setGroups(data)
        if(data.length <= 5 || getEmployeeCount(data) <= 20) {
          //隐藏class=adm-index-bar-sidebar的元素
          const sidebar = document.querySelector('.adm-index-bar-sidebar') as HTMLElement;
          if(sidebar) {
            sidebar.style.display = 'none';
          }
        }
        // console.log(res)
      }
    },
    onError: (error) => {
      console.log(error)
    }
  });

  const getEmployeeCount = (data: Group[]) => {
    let count = 0;
    data.forEach(item => {
      count += item.enterpriseStaffList.length;
    })
    return count;
  }

  // 删除指定 id 的员工
  const removeEmployeeById = (targetId: string) => {
    setGroups(prevGroups => {
      // 创建新的分组数组
      const newGroups = prevGroups.map(group => ({
        groupName: group.groupName,
        // 过滤掉匹配 id 的员工
        enterpriseStaffList: group.enterpriseStaffList.filter(
          staff => staff.id !== targetId
        )
      }));
      
      // 移除空的分组
      return newGroups.filter(group => group.enterpriseStaffList.length > 0);
    });
  };

  const { run: deleteEmployeeRun } = useRequest(deleteEmployee, {
    manual: true,
    onSuccess: (res) => {
      if (res.success) {
        removeEmployeeById(res.data);
      }
    },
    onError: (error) => {
      console.log(error)
    }
  })

  useEffect(() => {
    getEmployeeListRun()

    if (contentRef.current) {
      setTopDistance(contentRef.current.getBoundingClientRect().top);
    }

    if (dd?.env?.platform === "notInDingTalk") {
      return;
    }

    dd.biz.navigation.setMenu({
      items: [{
        id: "1",
        iconId: "add",
        text: "帮助",
        url: "https://citycdn.oss-cn-hangzhou.aliyuncs.com/images/addemployee20241220.png",
      }],
      onSuccess: () => {
        openUrl({ url: "/addEmployee", isRoute: true })
      }
    });

    return () => {
      // 离开页面时，恢复隐藏+号
      setHideDingRight();
    }
  }, []);

  const renderNoEmployee = () => {
    return (
      <DefaultInfo
        imgSrc="https://citycdn.oss-cn-hangzhou.aliyuncs.com/images/noemployee.png"
        title="暂无员工"
        btnText="新增员工"
        btnLink="/addEmployee"
      />
    )
  }

  return (
    <div className={styles.container}>
      <div className={styles.searchContainer}>
        <Search
          className={styles.search}
        />
      </div>
      <div className={styles.employeeListContainer}>
        <div
          ref={contentRef}
          style={{
            height: window.innerHeight - topDistance - 12,
            textAlign: 'left',
          }}
        >
          <IndexBar
            sticky={true}
            className={styles.indexBar}
          >
            {groups.map((group) => (
              <IndexBar.Panel key={group.groupName} index={group.groupName} title={group.groupName}>
                <List className={styles.employeeList}>
                  {group.enterpriseStaffList.map((item) => (
                    <SwipeAction
                      key={item.id}
                      ref={swipeActionRef}
                      rightActions={[{
                        text: '删除',
                        key: 'delete',
                        color: 'red',
                        onClick: (value) => {
                          deleteEmployeeRun({ id: item.id })
                        }
                      }]}
                    >
                      <List.Item
                        key={item.id}
                        prefix={
                          <div className={styles.avatarByName}>{item.staffName.slice(0, 1)}</div>
                        }
                        description={item.staffPhone}
                      >
                        {item.staffName}
                      </List.Item>
                    </SwipeAction>
                  ))}
                </List>
              </IndexBar.Panel>
            ))}
          </IndexBar>
        </div>
      </div>
      {
        hasEmployee && renderNoEmployee()
      }
    </div>
  );
}

export default EmployeeManagement;