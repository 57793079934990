import React, { useEffect, useMemo, useState } from "react";
import styles from "./index.module.scss";
import { Avatar, Button, Grid, Image, List } from "antd-mobile";
import Icon from "@/common/Component/Icon";
import { ExclamationCircleOutline, RightOutline } from "antd-mobile-icons";
import { useOpenUrl } from "@/hooks/useOpenUrl";
import usePageTitle from "@/hooks/usePageTitle";
import useUserStore from "@/store/global";
import { useShallow } from "zustand/react/shallow";
import { useRequest } from "ahooks";
import { AccountTypeEnum, getAccountsStatus } from "./service";
import {
  enterpriseApplyGet,
  StatusEnum,
} from "../Enterprise/services/enterpriseApplyGet";
import { getBankCard } from "../Wallet/Services";
import Cards from "./components/Cards";
import PageLoading from "@/common/Component/PageLoading";
import usePageHeader from "@/store/pageHeader";
interface IMyProps {
  isH5?: boolean;
}

/** 我的  */
const MyV2: React.FC<IMyProps> = (props) => {
  const { isH5 } = props;
  usePageTitle({ title: "我的" });
  const [setHeaderStyle] = usePageHeader(
    useShallow((state) => [state.setStyle])
  );
  const { data: statusData, loading: getAccountsStatusLoading } =
    useRequest(getAccountsStatus);
  const [getUserLoading, setGetUserLoading] = useState(false);
  const [dingUserInfo, getDingUser, corpId] = useUserStore(
    useShallow((state) => [
      state.dingUserInfo,
      state.getDingUser,
      state.corpId,
      state.visibleMoney,
      state.setVisibleMoney,
    ])
  );
  const { data: listBackCard, loading: getBankCardLoading } = useRequest(
    getBankCard,
    {}
  );

  const openUrl = useOpenUrl();
  const {
    data: applyData,
    loading: enterpriseApplyLoading,
    run: enterpriseApplyGetRun,
  } = useRequest(enterpriseApplyGet, {
    manual: true,
  });
  const avatar =
    "https://cdn.wuhuxianmai.cn/feed/1733196886985-80e6857f7a91674dd710945b825be538541e93aa47c448c04935a64ca4395cae";

  useEffect(() => {
    setGetUserLoading(true);
    getDingUser().finally(() => {
      setGetUserLoading(false);
    });
  }, [getDingUser]);
  useEffect(() => {
    corpId && enterpriseApplyGetRun({ applyOrgId: corpId });
  }, [corpId]);
  const enterpriseAction = useMemo(() => {
    /**
     * 餐补入驻
     */
    const action = {
      title: "餐补入驻",
      icon: "icon-qiyecanbu",
      link: {
        url: "/apply/enterprise/apply",
        isRoute: true,
      },
      onClick: () => {},
      tip: "",
    };
    switch (applyData?.data?.status) {
      case StatusEnum.PendingPlatformReview:
        action.tip = "审核中";
        break;
      case StatusEnum.ReviewFailed:
        action.tip = "审核未通过";
        break;
      case StatusEnum.ReviewPassed:
        action.tip = "已入驻";
        break;
      case StatusEnum.AccountBookOpened:
        action.tip = "已入驻";
        break;
      case StatusEnum.AccountBookBlock:
        action.tip = "已冻结";
        break;
      case StatusEnum.Cancelled:
        action.tip = "已注销";
        break;
      case StatusEnum.Void:
        action.tip = "已作废";
        break;
      default:
        action.tip = "所在组织未开通，去入驻";
    }
    return action;
  }, [applyData]);
  const { title, onClick, showAction, buttonText, actionList, showTip } =
    useMemo(() => {
      const actionList = [
        // {
        //   title: "实名认证",
        //   icon: "icon-waimaidingdan",
        //   link: {
        //     url: "",
        //     isRoute: true,
        //   },
        //   onClick: () => {},
        //   tip: "",
        // },
        // {
        //   title: "发票助手",
        //   icon: "icon-waimaidingdan",
        //   link: {
        //     url: "",
        //     isRoute: true,
        //   },
        //   onClick: () => {},
        //   tip: "",
        // },
      ];

      if (dingUserInfo?.userAdmin) {
        actionList.push(enterpriseAction);
        actionList.push({
          title: "员工管理",
          icon: "icon-yuangongguanli",
          link: {
            url: "/employeeManagement",
            isRoute: true,
          },
          onClick: () => {},
          tip: "",
        });
      }
      const obj = {
        title: "",
        onClick: () => {},
        showAction: false,
        showTip: false,
        buttonText: "",
        actionList,
      };
      const checkCard = () => {
        openUrl({
          url: "/oneCard",
          isRoute: true,
        });
      };
      const bindCard = () => {
        /**
         * 是否绑定过企业
         */
        let hasBind = !!listBackCard?.data?.length;
        if (hasBind) {
          openUrl({
            url: "/oneCard",
            isRoute: true,
          });
        } else {
          openUrl({
            url: "/openPay",
            isRoute: true,
          });
        }
      };

      switch (statusData?.data) {
        case AccountTypeEnum.BIND:
          obj.title = "已绑定";
          obj.onClick = checkCard;
          obj.showAction = false;
          obj.buttonText = "查看";
          break;
        case AccountTypeEnum.BLOCK:
          obj.title = "已冻结";
          obj.onClick = checkCard;
          obj.showAction = false;
          obj.buttonText = "查看";
          break;
        case AccountTypeEnum.CLOSED:
          obj.title = "已注销";
          obj.onClick = checkCard;
          obj.showAction = false;
          obj.buttonText = "查看";
          break;
        case AccountTypeEnum.NO_BIND:
          obj.title = "已开通，去绑定";
          obj.onClick = bindCard;
          obj.showAction = true;
          obj.showTip = true;
          obj.buttonText = "去绑定";
          break;
        case AccountTypeEnum.NO_ENTERPRISE:
          if (dingUserInfo?.userAdmin) {
            obj.onClick = () => {
              openUrl({
                url: "/apply/enterprise/apply",
                isRoute: true,
              });
            };
            obj.buttonText = "去开通";
            obj.showAction = true;
            obj.showTip = true;
            obj.title = "未开通";
          } else {
            obj.title = "未开通,请联系管理员开通";
            obj.showTip = true;
          }
          break;
        // case AccountTypeEnum.OPENED:
        //   obj.title = "已开户";
        //   break;
        default:
      }
      return obj;
    }, [statusData, dingUserInfo, enterpriseAction]);

  const listTips = [
    {
      image:
        "https://cdn.wuhuxianmai.cn/feed/1736330270623-0df83a7f728bd0e01fe106400ce6ff6c85abeb239e65ec71704c1b2c0ea07dec",
      text: "企业专享会员",
      onClick: () => {},
    },
    {
      image:
        "https://cdn.wuhuxianmai.cn/feed/1736475934736-bfb85e3c327a4df5e0523e345ffaef75a3cff60b5eb12ba3364354441d0e6319",
      text: "本地生活会员",
      onClick: () => {},
    },
  ];
  const listWallet = [
    {
      num: listBackCard?.data?.length || 0,
      text: "餐卡",
      onClick: () => {
        openUrl({
          url: "/oneCard",
          isRoute: true,
        });
      },
    },
    {
      num: 0,
      text: "银行卡",
      onClick: () => {
        openUrl({
          // url: "",
          isRoute: true,
        });
      },
    },
    {
      num: 0,
      text: "消费券",
      onClick: () => {
        openUrl({
          // url: "",
          isRoute: true,
        });
      },
    },
  ];
  const listOrder = [
    {
      image:
        "https://cdn.wuhuxianmai.cn/feed/1736479456551-ffe3c695e05acbec36bb8cc007f68e81d390923f96584f38a972b32fee35c847",
      text: "外卖订单",
      onClick: () => {
        openUrl({
          url: `${window.location.origin}/eleme?route_params=020`,
          isRoute: false,
          isOpenHalf: true,
        });
      },
    },
    {
      image:
        "https://cdn.wuhuxianmai.cn/feed/1736475931333-ae2f8f7290fa6142b1b6865325a15e3711fa3744a3905e1ea4d73b80f5313a8b",
      text: "到店订单",
      onClick: () => {
        openUrl({
          url: "/storeOrder",
          isRoute: true,
        });
      },
    },
    {
      image:
        "https://cdn.wuhuxianmai.cn/feed/1736475939030-11b7416bf07603a23cf50dff75e52b15fab2a9bf382862f2f14304fe5e5b49a5",
      text: "商城订单",
      onClick: () => {
        openUrl({
          // url: "/test",
          isRoute: true,
        });
      },
    },
  ];
  useEffect(() => {
    setHeaderStyle({ background: "transparent" });
    return () => {
      setHeaderStyle();
    };
  }, []);
  return (
    <>
      <div className={styles.containerBack} />
      <div className={styles.container}>
        <PageLoading
          loading={
            getBankCardLoading ||
            getAccountsStatusLoading ||
            getUserLoading ||
            enterpriseApplyLoading
          }
        >
          <div className={styles.content}>
            <div className={styles.header}>
              <Avatar
                className={styles.avatar}
                src={dingUserInfo?.userAvatar || avatar}
              />
              <div className={styles.info}>
                <div className={styles.name}>{dingUserInfo?.userName}</div>
                <div className={styles.num}>{dingUserInfo?.userMobile}</div>
              </div>
            </div>
            {showTip && (
              <div
                className={styles.tipAction}
                onClick={() => {
                  showAction && onClick();
                }}
              >
                <div className={styles.tipLeft}>
                  <ExclamationCircleOutline className={styles.tipLeftIcon} />
                  <span className={styles.title}>{title}</span>
                </div>
                <RightOutline className={styles.tipRightAction} />
              </div>
            )}
            {/* <div className={styles.listTips}>
    {listTips.map((item, index) => {
      return (
        <div key={index} className={styles.tipsItem}>
          <Image className={styles.tipsImage} src={item.image} />
          <div className={styles.tipsText}>{item.text}</div>
        </div>
      );
    })}
  </div> */}
            <Cards
              title="我的钱包"
              extra="查看账单"
              extraClick={() => {
                openUrl({
                  url: "/myBill",
                  isRoute: true,
                });
              }}
            >
              <Grid columns={3} className={styles.walletList}>
                {listWallet.map((item, index) => {
                  return (
                    <Grid.Item
                      onClick={() => item.onClick && item.onClick()}
                      key={index}
                      className={styles.walletItem}
                    >
                      <div className={styles.walletNum}>{item.num}</div>
                      <div className={styles.walletText}>{item.text}</div>
                    </Grid.Item>
                  );
                })}
              </Grid>
            </Cards>
            {!isH5 && (
              <Cards title="我的订单">
                <Grid columns={3} className={styles.orderList}>
                  {listOrder.map((item, index) => {
                    return (
                      <Grid.Item
                        onClick={() => item.onClick && item.onClick()}
                        key={index}
                        className={styles.orderItem}
                      >
                        <Image className={styles.orderImage} src={item.image} />
                        <div className={styles.orderText}>{item.text}</div>
                      </Grid.Item>
                    );
                  })}
                </Grid>
              </Cards>
            )}

            {!!actionList.length && !isH5 && (
              <Cards title="功能服务">
                <List className={styles.list}>
                  {actionList.map((item, index) => {
                    return (
                      <List.Item
                        arrowIcon={<RightOutline className={styles.icon} />}
                        className={styles.listItem}
                        onClick={() => {
                          openUrl(item.link);
                          item.onClick && item.onClick();
                        }}
                        key={index}
                      >
                        <div className={styles.left}>
                          <Icon icon={item.icon} className={styles.leftIcon} />
                          <span>{item.title}</span>
                        </div>
                        {item.tip && (
                          <div className={styles.right}>{item.tip}</div>
                        )}
                      </List.Item>
                    );
                  })}
                </List>
              </Cards>
            )}
          </div>
        </PageLoading>
      </div>
    </>
  );
};

export default MyV2;
