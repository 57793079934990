import apiClient from "src/common/Utils/APIClient";
// import { API_HOST } from "src/common/Config/Constant";

interface Params {
  tradeNo: string;
}

interface IRes {
  code: string;
  msg: string;
  data: any;
  success: boolean;
}

// const getBuyerPay = (params: any): any => async (): Promise<any> => {
const detailByNo = async(params: Params): Promise<IRes> => {

  const { tradeNo = "" } = params || {};
  const res = await apiClient.get("/dmall_payment/buyerPay/detailByNo", {
    param: {
      tradeNo: tradeNo,
    },
  });
  const { data = {} } = res || {};
  return data;
};



export { detailByNo };
