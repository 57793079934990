import { create } from "zustand";
import { persist, createJSONStorage } from "zustand/middleware";

interface WalletState {
  redirect?: string;
  setRedirect: (redirect: string) => void;
}

const useWalletStore = create<WalletState>()(
  persist(
    (set, get) => ({
      setRedirect: (redirect: string) => {
        set({ redirect });
      },
    }),
    {
      name: "wallet", // 存储中的项目名称，必须是唯一的
      storage: createJSONStorage(() => sessionStorage), // 使用sessionStorage作为存储
      partialize: (state) => ({
        // 仅存储特定的字段
        redirect: state.redirect,
      }),
    }
  )
);

export default useWalletStore;
