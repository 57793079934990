import React from "react";
import { List, Checkbox } from "antd-mobile";
import Icon from "src/common/Component/Icon";
import styles from "./index.module.scss";

function ThirdParty(props: any) {
  const { payInfo, onChangePayInfo, listStyle, data } = props;
  const { payMethodId } = payInfo || {};
  const { wechat, alipay, unionpay } = data || {};

  if (wechat?.total === 0 && alipay?.total === 0) {
    return null;
  }

  return (
    <div className={styles.app}>
      <List style={listStyle}>
        {
          alipay?.total > 0 ? (
            <List.Item
              className={styles.thirdPartyContainer}
              extra={
                <Checkbox
                  onChange={() =>
                    onChangePayInfo({
                      payMethodCd: alipay?.methodCd,
                      payMethodId: alipay?.methodId,
                      bankCardNo: alipay?.cardId,
                      feeAmount: alipay?.feeAmount,
                    })
                  }
                  checked={payMethodId === alipay?.methodId}
                />
              }
              prefix={
                <img src="https://citycdn.oss-cn-hangzhou.aliyuncs.com/images/zhifubaologo.png" className={styles.alipay} alt="支付宝" />
              }
            >
              {alipay?.methodName}
            </List.Item>
          ) : null
        }
        {
          wechat?.total > 0 ? (
            <List.Item
              className={styles.thirdPartyContainer}
              extra={
                <Checkbox
                  onChange={() =>
                    onChangePayInfo({
                      payMethodCd: wechat?.methodCd,
                      payMethodId: wechat?.methodId,
                      bankCardNo: wechat?.cardId,
                      feeAmount: wechat?.feeAmount,
                    })
                  }
                  checked={payMethodId === wechat?.methodId}
                />
              }
              prefix={
                <img src="https://citycdn.oss-cn-hangzhou.aliyuncs.com/images/wechatpaylogo.png" className={styles.alipay} alt="微信支付" />
              }
            >
              {wechat?.methodName}
              </List.Item>
          ) : null
        }
        {
          unionpay?.total > 0 ? (
            <List.Item
              className={styles.thirdPartyContainer}
              extra={
                <Checkbox
                  onChange={() =>
                    onChangePayInfo({
                      payMethodCd: unionpay?.methodCd,
                      payMethodId: unionpay?.methodId,
                      bankCardNo: unionpay?.cardId,
                      feeAmount: unionpay?.feeAmount,
                    })
                  }
                  checked={payMethodId === unionpay?.methodId}
                />
              }
              prefix={
                <Icon icon="icon-zhifu-yinlianzhifu" className={styles.unionpay} />
              }
            >
              {unionpay?.methodName}
              </List.Item>
          ) : null
        }
      </List>
    </div>
  );
}

export default ThirdParty;
