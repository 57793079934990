import React from 'react'
import { Image } from 'antd-mobile'
import styles from './index.module.scss'

const DiscountAuthorization: React.FC = () => {
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        {/* 这里添加你的页面内容 */}
      </div>
      <Image src="https://citycdn.oss-cn-hangzhou.aliyuncs.com/images/fdebae325da6baf7810be928da17c79e8e5a2eb1.png" />
      <Image src="https://citycdn.oss-cn-hangzhou.aliyuncs.com/images/7e3716a0c05e41bb694a4297dafa753a2321e8ce.jpg" />
    </div>
  )
}

export default DiscountAuthorization
