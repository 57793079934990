import apiClient from "src/common/Utils/APIClient";

interface IRes {
  code: string;
  msg: string;
  data: any;
  success: boolean;
}

interface IParams {
  staffName: string;
  staffPhone: string;
}

const addEmployee = (params: IParams): Promise<IRes> => {
  return apiClient.post("/dmall-customer/enterprise/staff", params);
};

export { addEmployee };