import axios from "@/common/Utils/APIClient";

export interface IGetFeedInfoPageReq {
  /**
   * 当前页码
   */
  page: number;
  /**
   * 每页条数
   */
  size: number;
  bizType: string;
}
export interface IGetFeedInfoPageRes {
  success: boolean;
  errorMsg: string;
  errorCode: string;
  data: Data;
}

export interface Data {
  result: Result[];
  hasMore: boolean;
}

export interface Result {
  uniqueId: string;
  title: string;
  desc: string;
  picUrl: string;
  content: string;
  contentType: string;
  modelData: ModelData;
  coverImg: string;
}

export interface ModelData {
  authorInfo: AuthorInfo;
}

export interface AuthorInfo {
  name: string;
  avatar: string;
}

/**
 * 获取资讯列表
 * @param data
 * @returns
 */
export const getFeedInfoPage = (
  data: IGetFeedInfoPageReq
): Promise<IGetFeedInfoPageRes> => {
  return axios.get("/dmall_customer/ding/ua/feed", {
    param: {
      ...data,
      source: "wuhu",
    },
  });
};
