import axios, { IBaseRes } from "@/common/Utils/APIClient";
const uploadUrl = "/dmall_customer/tot/shop/delUpload";

interface IParams {
  id: string;
}
/**
 * 删除上传文件
 */
export const delUpload = (data: IParams): Promise<IBaseRes<string>> => {
  return axios.get(uploadUrl, { param: data });
};
