import React, { useEffect, useRef } from "react";
import { Input } from "antd-mobile";
import { InputProps, InputRef } from "antd-mobile/es/components/input";
import styles from "./index.module.scss";

interface IInputProps extends InputProps {}

/** 输入框  */
const Inputs: React.FC<IInputProps> = (props) => {
  const { placeholder, value, onChange, ...rest } = props;
  const inputRef = useRef<InputRef>(null);
  const [localValue, setLocalValue] = React.useState<string | undefined>(value);
  useEffect(() => {
    setLocalValue(value);
  }, [value]);
  const placeholderClick = () => {
    // 修复定位问题，点击不到输入框问题；
    inputRef.current?.focus();
  };

  return (
    <div className={styles.container}>
      {!localValue && (
        <div className={styles.placeholder} onClick={placeholderClick}>
          <svg
            // t="1730683968758"
            className={styles.searchIcon}
            viewBox="0 0 1024 1024"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            p-id="4267"
            // width="32"
            // height="32"
          >
            <path
              d="M888.703059 847.158363 733.373365 691.826622c50.040696-59.650565 80.190367-136.551002 80.190367-220.507136 0-189.613522-153.703661-343.318206-343.31616-343.318206-189.616592 0-343.31923 153.703661-343.31923 343.318206s153.703661 343.318206 343.31923 343.318206c85.053118 0 162.867368-30.94171 222.838228-82.163301l155.141406 155.141406c11.174504 11.17655 29.300326 11.17655 40.47483 0C899.859144 876.440269 899.859144 858.334913 888.703059 847.158363zM470.248595 757.416456c-158.006663 0-286.10004-128.090307-286.10004-286.097994 0-158.006663 128.093377-286.099017 286.10004-286.099017s286.079574 128.093377 286.079574 286.099017C756.32817 629.326149 628.254236 757.416456 470.248595 757.416456z"
              fill="#86909c"
              p-id="4268"
            />
          </svg>
          <span>{placeholder}</span>
        </div>
      )}
      <Input
        {...rest}
        value={localValue}
        ref={inputRef}
        onChange={(v) => {
          setLocalValue(v);
          onChange && onChange(v);
        }}
        className={styles.input}
      />
    </div>
  );
};

export default Inputs;
