import * as dd from "dingtalk-jsapi";
/**
 * 禁用iOS下webview下拉
 */
export const disableIOSWebView = () => {
  if (dd?.env?.platform === "notInDingTalk") {
    return;
  }
  // ios 禁止下拉
  if (window.navigator.userAgent.indexOf("iPhone") !== -1) {
    dd.ui.webViewBounce.disable({
      onSuccess: function (res: any) {
        // 调用成功时回调
        //   console.log(res);
      },
      onFail: function (err: any) {
        // 调用失败时回调
        //   console.log(err);
      },
    });
  }
};
