import * as dd from 'dingtalk-jsapi';

const HOMEPAGE_URL_H5 = 'https://n.dingtalk.com/dingding/ding-edu-life-home/city/index.html?cityCode=340200&corpId=ding89550f75d92db09824f2f5cc6abecb85&preview=true';
const HOMEPAGE_URL_APP = '/tabBar';
const TYPE_H5 = 'h5';
const TYPE_APP = 'app';

export const getAppId = (appType?: string) => {
  if(appType === TYPE_H5){
    // h5_1
    return 'h5_1';
  } else {
    return 'app_1';
  }
};

export const goToHomePage = (appType?: string) => {
  // if(appType === TYPE_H5){
  //   window.location.href = HOMEPAGE_URL_H5;
  // } else {
    window.location.href = HOMEPAGE_URL_APP;
  // }
};

export const homePageLink = (appType?: string) => {
  if(appType === TYPE_H5){
    return HOMEPAGE_URL_H5;
  } else {
    return HOMEPAGE_URL_APP;
  }
};
