import React, { useEffect, useState } from "react";
import { Result, Toast } from "antd-mobile";
import { Link, useSearchParams } from "react-router-dom";
import usePageTitle from "@/hooks/usePageTitle";
import { useRequest } from "ahooks";
import { getBuyerPay } from "./Services";
import { formatCurrency } from "@/common/Utils/money";
import { RightOutline } from "antd-mobile-icons";
import styles from "./index.module.scss";
import { pxToRem } from "@/common/Utils/rem";

interface Detail {
  payAmount: string;
  subject: string;
  buyAmount: string;
  feeAmout: string;
  successTime: string;
  orderNo: string;
  orderType: string;
  methodCd: string;
  discountAmount: string;
  body: string;
  buyerMobile: string;
  feeAmount: string;
  orderAmount: string;
  appNo: string;
  refundAmount: string;
  relaOrderId: string;
}


function BillDetail() {
  usePageTitle({ title: "账单详情" });

  const [params] = useSearchParams();
  const payOrderId = params.get('id');
  const [detail, setDetail] = useState<Detail>();

  const { run } = useRequest(getBuyerPay, {
    manual: true,
    onSuccess: (res: any) => {
      const { success, msg, data } = res || {};
      if (success) {
        console.log(data);
        setDetail(data);
      } else {
        Toast.show({
          content: msg || '获取详情失败',
          afterClose: () => {
            // window.history.back();
          }
        });
      }
    },
  });

  useEffect(() => {
    if (payOrderId) {
      run({ payOrderId });
    } else {
      Toast.show({
        content: '缺少必要参数',
        afterClose: () => {
          window.history.back();
        }
      });
    }
  }, [payOrderId]);

  const renderPrice = (detail: Detail) => {
    if (detail.orderType === 'p') {
      return <div className={styles.price}>-{formatCurrency(Number(detail.payAmount))}</div>;
    } else if (detail.orderType === 'r') {
      return <div className={styles.price}>+{formatCurrency(Number(detail.refundAmount))}</div>;
    }
    return null;
  };

  const renderStatus = (orderType: string | undefined) => {
    if (orderType === 'p') {
      return '支付成功';
    } else if (orderType === 'r') {
      return '退款成功';
    }
  };

  const renderPayMethod = (methodCd: string) => {
    if (methodCd === 'onepay') {
      return '钱包余额';
    } else if (methodCd === 'alipay') {
      return '支付宝';
    } else if (methodCd === 'wechat') {
      return '微信支付';
    }
  };

  const payMoney = (order: Detail) => {
    if (order.appNo === '51851851990') {
      return order.orderAmount;
    } else if (order.appNo === 'dd-wh') {
      return order.buyAmount;
    }
    return 0;
  };

  if (!detail) return null;

  const { subject, discountAmount, body, feeAmount, orderType, successTime, methodCd, orderNo, relaOrderId, refundAmount } = detail;

  return (
    <div className={styles.container}>
      <Result
        className={styles.result}
        status='success'
        title={subject}
        description={renderPrice(detail)}
      />
      {
        detail.orderType === 'p' && (
          <div className={styles.resultContent}>
            {
              Number(refundAmount) > 0 && <Link className={[styles.list, styles.refund].join(' ')} to={`/billDetail?id=${relaOrderId}`}>
                  <span className={styles.label}>退款记录</span>
                  <span className={styles.value}>
                    已退款 ¥{formatCurrency(Number(refundAmount))}<RightOutline className={styles.rightIcon} fontSize={pxToRem(18)} />
                  </span>
              </Link>
            }
            <li className={styles.list}>
              <span className={styles.label}>订单状态</span>
              <span className={styles.value}>{renderStatus(orderType)}</span>
            </li>
            <li className={styles.list}>
              <span className={styles.label}>订单金额</span>
              <span className={styles.value}>¥{formatCurrency(Number(payMoney(detail)))}</span>
            </li>
            {
              Number(feeAmount) > 0 && (
                <li className={styles.list}>
                  <span className={styles.label}>技术服务费</span>
                  <span className={styles.value}>¥{formatCurrency(Number(feeAmount))}</span>
                </li>
              )
            }
            {
              Number(discountAmount) > 0 && (
                <li className={[styles.list, styles.discount].join(' ')}>
                  <span className={styles.label}>{body}</span>
                  <span className={styles.value}>减¥{formatCurrency(discountAmount)}</span>
                </li>
              )
            }
            <li className={styles.list}>
              <span className={styles.label}>支付时间</span>
              <span className={styles.value}>{successTime}</span>
            </li>
            <li className={styles.list}>
              <span className={styles.label}>支付方式</span>
              <span className={styles.value}>{renderPayMethod(methodCd)}</span>
            </li>
            <li className={styles.list}>
              <span className={styles.label}>订单号</span>
              <span className={styles.value}>{orderNo}</span>
            </li>
          </div>
        )
      }
      {
        detail.orderType === 'r' && (
          <div className={styles.resultContent}>
            <li className={styles.list}>
              <span className={styles.label}>退款时间</span>
              <span className={styles.value}>{successTime}</span>
            </li>
            <li className={styles.list}>
              <span className={styles.label}>退款方式</span>
              <span className={styles.value}>{renderPayMethod(methodCd)}</span>
            </li>
            <li className={styles.list}>
              <span className={styles.label}>订单号</span>
              <span className={styles.value}>{orderNo}</span>
            </li>
            <li className={styles.list}>
              <span className={styles.label}>原支付记录</span>
              <span className={styles.value}>
                <Link to={`/billDetail?id=${relaOrderId}`}>查看</Link>
              </span>
            </li>
          </div>
        )
      }
      
    </div>
  );
}

export default BillDetail;