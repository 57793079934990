import axios, { IBaseRes } from "src/common/Utils/APIClient";

interface IParamData {
  shopId: string;
  buyAmount: number;
  noDiscountAmount: number;
  remark?: string;
}

export const place4shop = (
  param?: IParamData
): Promise<IBaseRes<any>> => {
  console.log(param)
  return axios.post("/dmall_payment/buyerPay/place4shop", param );
};

interface IShopInfoParam {
  shopId: string;
}

export const getShopInfo = async (
  param: IShopInfoParam
): Promise<any> => {
  const res = await axios.get("/dmall-multishop/arrive/get/discount", {
    param: {
      shopId: param.shopId
    }
  });
  const { data } = res;
  return data;
};