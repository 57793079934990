import ApplyLayout from "@/common/Component/ApplyPage";
import usePageTitle from "@/hooks/usePageTitle";
import React, { useState } from "react";
import { Form, ImageUploader, ImageUploadItem, Toast } from "antd-mobile";
import Buttons from "@/common/Component/Button";
import styles from "./index.module.scss";
import FormItem from "@/common/Component/FormItem";
import FormCardTitle from "@/common/Component/FormCardTitle";
import { useRequest } from "ahooks";
import { ProtocolTypeEnum } from "../../enum";
import { useOpenUrl } from "@/hooks/useOpenUrl";
import PageLoading from "@/common/Component/PageLoading";
import useArriveStore from "@/store/arriveStore";
import { useShallow } from "zustand/react/shallow";
import Compressor from "compressorjs";
import { uploadShopOne } from "../../service/uploadOne";
import { confirmUpload } from "../../service/confirmUpload";
import { UploadTask } from "antd-mobile/es/components/image-uploader";
import { delUpload } from "../../service/uploadOneDel";
import { delUploadAll } from "../../service/delUploadAll";
import { useSearchParams } from "react-router-dom";

interface IUploadProtocolProps {}

/** 上传协议  */
const UploadProtocol: React.FC<IUploadProtocolProps> = (props) => {
  const {} = props;
  usePageTitle({ title: "商户签约到店服务" });
  const [form] = Form.useForm();
  const [searchParams, setSearchParams] = useSearchParams();
  const [localShopId, callback] = useArriveStore(
    useShallow((state) => [
      state.registerShopId,
      state.registerStoreCallbackUrl,
    ])
  );
  const shopId = localShopId || (searchParams.get("shopId") as string);
  const [loadingCooper, setLoadingCooper] = useState(false);
  const [loadingAgentOperation, setLoadingAgentOperation] = useState(false);
  const { run: confirmUploadRun, loading: confirmLoading } = useRequest(
    confirmUpload,
    {
      manual: true,
      onSuccess: (res) => {
        if (res.data && res.success) {
          Toast.show({
            icon: "success",
            content: "提交成功",
          });
          callback &&
            openUrl({
              url: decodeURIComponent(callback),
              isRoute: false,
              isOpenHalf: false,
            });
        } else {
          Toast.show({
            icon: "fail",
            content: "确认失败",
          });
        }
      },
    }
  );
  const { runAsync: runDelete, loading: delLoading } = useRequest(delUpload, {
    manual: true,
    onSuccess: (res) => {
      if (res.data && res.success) {
        // Toast.show({
        //   icon: "success",
        //   content: "删除成功",
        // });
      } else {
        Toast.show({
          icon: "fail",
          content: "删除失败",
        });
      }
    },
  });

  const openUrl = useOpenUrl();
  const onFinish = (values: any) => {
    console.log("values", values);

    if (!shopId) {
      Toast.show({
        icon: "fail",
        content: "请选择店铺",
      });
      return;
    }
    const cooperIds =
      values.image1?.map((item: ImageUploadItem) => item.key) || [];
    const agencyIds =
      values.image2?.map((item: ImageUploadItem) => item.key) || [];
    confirmUploadRun({
      elmShopNo: shopId,
      cooperIds,
      agencyIds,
    });
  };
  const beforeUpload = (file: File, files: File[]): Promise<File | null> => {
    return new Promise((resolve, reject) => {
      new Compressor(file, {
        quality: 0.7,
        maxWidth: 800,
        convertSize: 1024 * 1024,
        mimeType: "image/jpeg", // 转换为 JPEG 格式
        success(result: Blob | File) {
          const newfile = new File([result], file.name);
          resolve(newfile);
        },
        error(err: any) {
          reject({ message: "文件压缩失败" });
          Toast.show({
            icon: "fail",
            content: err.message,
          });
        },
      });
    });
  };
  const upload = (
    file: File,
    protocolType: ProtocolTypeEnum
  ): Promise<ImageUploadItem> => {
    return new Promise((resolve, reject) => {
      return uploadShopOne({
        file,
        elmShopNo: shopId,
        protocolType,
      })
        .then((res) => {
          if (res.data && res.success) {
            resolve({
              url: res.data?.ossUrl || URL.createObjectURL(file),
              extra: { file },
              key: res.data?.id,
            });
          } else {
            reject("上传失败");
          }
        })
        .catch((e) => {
          reject(e);
        });
    });
  };
  const onUploadQueueChange = (
    tasks: UploadTask[],
    protocolType: ProtocolTypeEnum
  ) => {
    console.log("tasks", tasks);
    const haspending = tasks.some((item) => item.status === "pending");
    const hasFail = tasks.some((item) => item.status === "fail");
    if (protocolType === ProtocolTypeEnum.Cooperate) {
      setLoadingCooper(haspending || hasFail);
    }
    if (protocolType === ProtocolTypeEnum.AgentOperation) {
      setLoadingAgentOperation(haspending || hasFail);
    }
  };
  return (
    <PageLoading loading={confirmLoading}>
      <ApplyLayout>
        <Form
          initialValues={{}}
          layout="horizontal"
          onFinish={onFinish}
          mode="card"
          className={styles.form}
          form={form}
          onValuesChange={(_, values) => {
            console.log(values, _);
          }}
        >
          <FormCardTitle title="上传协议" />
          <FormItem
            required
            className={styles.item}
            name="image1"
            label="合作协议"
            rules={[{ required: true, message: "必填" }]}
          >
            <ImageUploader
              beforeUpload={beforeUpload}
              upload={(file) => upload(file, ProtocolTypeEnum.Cooperate)}
              multiple
              onDelete={(item) => {
                console.log("item", item);
                return new Promise((resolve) => {
                  runDelete({ id: item.key?.toString() || "" })
                    .then((res) => {
                      if (res.data && res.success) {
                        resolve(true);
                      } else {
                        resolve(false);
                      }
                    })
                    .catch((e) => {
                      resolve(false);
                    });
                });
              }}
              onUploadQueueChange={(tasks: UploadTask[]) =>
                onUploadQueueChange(tasks, ProtocolTypeEnum.Cooperate)
              }
            />
          </FormItem>
          <FormItem
            required
            className={styles.item}
            name="image2"
            label="代运营协议及授权函"
            rules={[{ required: true, message: "必填" }]}
          >
            <ImageUploader
              beforeUpload={beforeUpload}
              upload={(file) => upload(file, ProtocolTypeEnum.AgentOperation)}
              multiple
              onUploadQueueChange={(tasks: UploadTask[]) =>
                onUploadQueueChange(tasks, ProtocolTypeEnum.AgentOperation)
              }
            />
          </FormItem>
        </Form>
        <div className={styles.footer}>
          <Buttons
            color="primary"
            block
            onClick={() => {
              form.submit();
            }}
            loading={confirmLoading}
            disabled={loadingCooper || loadingAgentOperation}
          >
            提交
          </Buttons>
        </div>
      </ApplyLayout>
    </PageLoading>
  );
};

export default UploadProtocol;
