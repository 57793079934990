import React from "react";
import OneCard from "../OneCard";
import BankCard from "../BankCard";
// import CouponCard from "../CouponCard";
import ThirdParty from "../ThirdParty";
import { STATIC_HOST } from "src/common/Config/Constant";
import styles from "./index.module.scss";

const listStyle = {
  "--border-top": "solid 0px #ccc",
  "--border-bottom": "solid 0px #ccc",
};

function Content(props: any) {
  const { payInfo, onChangePayInfo, setFeeAmount, data = {} } = props;
  const { payMethods } = data || {};
  console.log('payMethods', payMethods);

  return (
    <div className={styles.app}>
      {
        payMethods?.onePay?.total > 0 ? (
          <div className={styles.content}>
            <div className={styles.head}>
              <div className={styles.left}>
              <img src={`${STATIC_HOST}/logo1.png`} alt="" />
              我的钱包
            </div>
            {/* <div className={styles.right}>
              安全有保障
              <img src={`${STATIC_HOST}/9d2c6685d64f61e5.png`} alt="" />
            </div> */}
          </div>
          <OneCard
            orderInfo={data}
            data={payMethods?.onePay}
            payInfo={payInfo}
            onChangePayInfo={onChangePayInfo}
            setFeeAmount={setFeeAmount}
          />
          </div>
        ) : null
      }
      {/* <BankCard
        listStyle={listStyle}
        payInfo={payInfo}
        data={payMethods?.bankCard}
        onChangePayInfo={onChangePayInfo}
      /> */}
      {/* <CouponCard
        listStyle={listStyle}
        coupon={coupon}
        onChangeCoupon={onChangeCoupon}
      /> */}
      <ThirdParty
        listStyle={listStyle}
        payInfo={payInfo}
        data={payMethods}
        onChangePayInfo={onChangePayInfo}
      />
    </div>
  );
}

export default Content;
