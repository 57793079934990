import axios, { IBaseRes } from "src/common/Utils/APIClient";

export interface IParamData {
  beginDate?: string;
  endDate?: string;
  beginAmount?: number;
  endAmount?: number;
  pageNum?: number;
  pageSize?: number;
  columns?: string;
  orders?: string;
}

export const getBillList = (
  param?: IParamData
): Promise<IBaseRes<any>> => {
  return axios.get("/dmall_payment/buyerPay/billPage", {
    param: {
      pageNum: 1,
      pageSize: 20,
      orders: 'DESC',
      columns: 'createAt',
      ...param,
    },
  });
};