import apiClient from "src/common/Utils/APIClient";
// import { API_HOST } from "src/common/Config/Constant";

const loginToken = async (data: {
  corpId: string;
  route?: string;
  clientId: string;
}) => {
  const res = await apiClient.get("/dmall_customer/ding/dd/token", {
    param: data,
  });

  return res;
};

const getDingTalkConfig = async (params: any) => {
  const { url = "" } = params || {};
  const res = await apiClient.get("/dmall_customer/config", {
    param: {
      url,
    },
  });
  const { result = {} } = res || {};

  return result;
};

const dingTalkLogin = async (params: any) => {
  const { authCode = "" } = params || {};
  const res = await apiClient.get("/dmall_customer/ding/login", {
    param: {
      authCode,
    },
  });
  const { result = {} } = res || {};

  return result;
};

export { loginToken, getDingTalkConfig, dingTalkLogin };
