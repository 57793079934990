import React, { useEffect, useRef, useState } from "react";
import { useRequest } from "ahooks";
import { useParams, useNavigate } from "react-router-dom";
import { getPartnerCode } from "./Services";
import { Toast, Image, Tabs } from "antd-mobile";
import Swiper, { SwiperRef } from 'antd-mobile/es/components/swiper'
import styles from './index.module.scss';
import { pxToRem } from "@/common/Utils/rem";

interface IData {
  title: string;
  content: string;
  name: string;
}

function PartnerCode() {
  const { code } = useParams<{ code: string }>();
  const [data, setData] = useState<IData[]>([]);
  const swiperRef = useRef<SwiperRef>(null);
  const [activeIndex, setActiveIndex] = useState(0);
  const navigate = useNavigate();

  const { run } = useRequest(getPartnerCode, {
    manual: true,
    onSuccess: (res) => {
      const { success, msg, data } = res;
      if (success) {
        setData(data)
      } else {
        Toast.show({
          content: msg || '合作伙伴码无效',
          afterClose: () => {
            navigate('/');
          }
        });
      }
    },
  });

  useEffect(() => {
    if (code) {
      run({ unionCode: code });
    }
  }, [code, run]);

  if (data.length === 0) {
    return null;
  }

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <Tabs
          activeLineMode='fixed'
          activeKey={activeIndex.toString()}
          onChange={(key) => {
            const index = Number(key)
            setActiveIndex(index)
            swiperRef.current?.swipeTo(index)
          }}
          style={{
            '--active-line-height': pxToRem(8),
            '--fixed-active-line-width': pxToRem(40),
            '--title-font-size': pxToRem(28),
            '--active-line-color': '#1879FF',
            '--active-line-border-radius': pxToRem(4),
            '--active-title-color': '#242931',
          }}
          className={styles.tabs}
        >
          {data.map((item, index) => (
            <Tabs.Tab title={item.title} key={index} />
          ))}
        </Tabs>
        <Swiper
          className={styles.swiper}
          direction='horizontal'
          loop
          indicator={() => null}
          ref={swiperRef}
          defaultIndex={activeIndex}
          onIndexChange={index => {
            setActiveIndex(index)
          }}
        >
          {data.map((item, index) => (
            <Swiper.Item>
              <div className={styles.qrCodeBg}>
                <Image className={styles.qrCode} src={'data:image/png;base64,' + item.content} />
              </div>
            </Swiper.Item>
          ))}
        </Swiper>
        <div className={styles.partnerInfo}>{data?.[activeIndex]?.name}：{code}</div>
      </div>
    </div>
  );
}

export default PartnerCode;