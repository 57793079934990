import React, { useCallback } from "react";
import { List, Checkbox, Popover } from "antd-mobile";
import { QuestionCircleOutline } from "antd-mobile-icons";
import { regFenToYuan, getBankCardLastFour } from "src/common/Utils";
import { useOpenUrl } from "@/hooks/useOpenUrl";
import { useRequest } from "ahooks";
import { accounts } from "../../Services";
import styles from "./index.module.scss";

const FeeAmount = (props: any = {}) => {
  const { feeAmount } = props;

  if (!feeAmount || feeAmount === 0) {
    return null;
  }

  return (
    <div className={styles.list}>
      <div>
        <span className={styles.detalText}>技术服务费 </span>
        <Popover
          mode="dark"
          placement="bottom"
          content="什么是技术服务费、为什么收取服务费、在哪里开发票"
          trigger="click"
        >
          <QuestionCircleOutline color="#86909C" className={styles.question} fontSize={15} />
        </Popover>
      </div>
      <span className={styles.detalValue}>¥{regFenToYuan(feeAmount)}</span>
    </div>
  );
};

const ListItem = (props: any) => {
  const {
    onChange,
    checked,
    // wayName,
    feeAmount = 0,
    payAmount = 0,
    cardNumber,
    balance = 0,
    canBeChecked = true,
  } = props || {};
  
  return (
    <React.Fragment>
      <List.Item className={styles.item} extra={<Checkbox onChange={onChange} checked={checked} disabled={!canBeChecked} />}>
        {/* {
          balance < feeAmount + payAmount ? 
          <span>钱包<span style={{ color: '#86909C' }}>(余额不足，&yen;{regFenToYuan(balance)})</span></span> : 
          <span>钱包(&yen;{regFenToYuan(balance)})</span>
        } */}
        <div className={styles.detalTitle}>钱包（{getBankCardLastFour(cardNumber)}）</div>
        {
          balance < feeAmount + payAmount ? 
          <div className={styles.detalText}>余额不足，剩余: &yen;{regFenToYuan(balance)}</div> : 
          <div className={styles.detalText}>可用: &yen;{regFenToYuan(balance)}</div>
        }

      </List.Item>
      {/* {checked ? (
        <List.Item className={styles.detail}>
          <div className={styles.list}>
            <span>商品总价格</span>
            <span>¥{regFenToYuan(payAmount)}</span>
          </div>
          <FeeAmount feeAmount={feeAmount} />
        </List.Item>
      ) : null} */}
    </React.Fragment>
  );
};

function OneCard(props: any) {
  const { payInfo, onChangePayInfo, data = {}, orderInfo = {} } = props;
  const { payAmount = 0 } = orderInfo || {};
  const { methodName = '', feeAmount = 0, methodId, methodCd } = data || {};
  const { payMethodId, bankCardNo } = payInfo || {};
  const { data: accountsData } = useRequest(accounts);
  const bankCards = accountsData?.data || [];
  const openUrl = useOpenUrl();


  const onClick = useCallback(() => {
    openUrl({
      url: '/openPay',
      isRoute: true,
      params: { 
        redirect: encodeURIComponent(window.location.href)
      },
    });
  }, []);

  const renderOpen = () => {
    if (bankCards.length === 0) {
      return (
          <List.Item className={styles.add} clickable onClick={onClick}>
            添加一卡通
          </List.Item>
      )
    }
  }

  const renderFee = (payAmount: number, feeAmount: number) => {
    return (
      <List.Item className={styles.detail}>
        <div className={styles.list}>
          <span className={styles.detalText}>商品总价格</span>
          <span className={styles.detalValue}>¥{regFenToYuan(payAmount)}</span>
        </div>
        <FeeAmount feeAmount={feeAmount} />
      </List.Item>
    )
  }

  return (
    <div className={styles.app}>
      <List>
        {renderOpen()}
        {
          bankCards.map((el: any, index: number) => {
            const { bankName, bankCardNo: bankCardNoCurrent, opPhone, balance } = el || {};
            const checked = payMethodId === methodId && bankCardNo === bankCardNoCurrent;
            const canBeChecked = balance > feeAmount + payAmount ? true : false;

            return (
              <ListItem
                key={bankCardNoCurrent}
                checked={checked}
                canBeChecked={canBeChecked}
                wayName={bankName}
                feeAmount={feeAmount}
                payAmount={payAmount}
                balance={balance}
                cardNumber={getBankCardLastFour(bankCardNoCurrent)}
                onChange={() =>
                  onChangePayInfo({
                    payMethodCd: methodCd,
                    payMethodId: methodId,
                    bankCardNo: bankCardNoCurrent,
                    phone: opPhone,
                    feeAmount: feeAmount,
                  })
                }
              />
            );
          })
        }
        {
          payMethodId === methodId && renderFee(payAmount, feeAmount)
        }
      </List>
    </div>
  );
}

export default OneCard;
