/**
 * 协议类型
 */
export enum ProtocolTypeEnum {
  /**
   * 合作协议
   */
  Cooperate = 1,
  /**
   * 代运营协议
   */
  AgentOperation = 2,
}
/**
 * 店铺审核状态
 */
export enum AuditStatusEnum {
  /**
   * 待审核
   */
  WAIT = 1,
  /**
   * 审核通过
   */
  AUDIT_PASS = 2,
  /**
   * 审核不通过
   */
  AUDIT_NO_PASS = 3,
  /**
   * 全部
   */
  ALL = -1,
}
