import React, { useEffect, useMemo, useState } from "react";
import Header from "../Header";
import AddCard from "../AddCard";
import CustomCard from "../CustomCard";
import { replaceBankCard } from "src/common/Utils";
import styles from "./index.module.scss";
import { Link } from "react-router-dom";
import { Accounts } from "../../Services";
import { pxToRem } from "@/common/Utils/rem";

interface IMetroCardProps {
  detialRoute?: string;
  bankCards?: Accounts[];
  // methodName?: string;
  title: string;
  subTitle: string;
}
function MetroCard(props: IMetroCardProps) {
  const { detialRoute = "", bankCards, title = "", subTitle = "" } = props;
  const [visible, setVisible] = useState(false);

  // if (!bankCards || bankCards.length === 0) {
  //   return null;
  // }
  useEffect(() => {}, [bankCards]);
  const onToggle = () => {
    const visibleTemp = !visible;
    setVisible(visibleTemp);
    // setValues(visibleTemp ? bankCards : bankCards?.slice(0, 3));
  };
  const showValue = useMemo(() => {
    // return visible ? bankCards : bankCards?.slice(0, 3);
    return bankCards;
  }, [bankCards, visible]);

  return (
    <div className={styles.container}>
      <div className={styles.metroCard}>
        <Header
          title={title}
          visible={visible}
          onToggle={onToggle}
          total={bankCards?.length}
          showToggle={(bankCards?.length || 0) > 1}
        />
        <div className={styles.app}>
          {showValue?.map((el, index: number) => {
            const { accountId, accountName, bankCardNo } = el || {};
            const top = 120;
            const style: React.CSSProperties = visible
              ? { marginTop: 0 }
              : { marginTop: index === 0 ? 0 : `-${pxToRem(top)}` };

            return (
              <div
                className={styles.card}
                style={{
                  ...style,
                  zIndex: index,
                }}
              >
                <Link to={`${detialRoute}?cardId=${accountId}`} key={accountId}>
                  <CustomCard
                    title={accountName}
                    cardNumber={replaceBankCard(bankCardNo)}
                  />
                </Link>
              </div>
            );
          })}
        </div>
      </div>
      <AddCard text={subTitle} type="onePay" />
    </div>
  );
}

export default MetroCard;
