import React from "react";
import styles from "./index.module.scss";
import { RightOutline } from "antd-mobile-icons";

interface CardsProps {
  title?: string;
  extra?: string;
  children?: React.ReactNode;
  extraClick?: () => void;
}

const Cards: React.FC<CardsProps> = (props) => {
  const { title, extra, children, extraClick } = props;
  return (
    <div className={styles.contentCard}>
      <div className={styles.cardTitle}>
        <span>{title}</span>
        <span className={styles.extra} onClick={extraClick}>
          {extra}
          {extraClick && <RightOutline className={styles.icon} />}
        </span>
      </div>
      <div className={styles.content}>{children}</div>
    </div>
  );
};

export default React.memo(Cards);
