import React, { useMemo } from "react";
import { Ellipsis, Grid, Image, Space } from "antd-mobile";

import chunk from "lodash/chunk";
import type { IBannerList } from "@/types/config";
import { IDiverseCardFooter } from "../../type";
import styles from "./index.module.scss";
import { useOpenUrl } from "@/hooks/useOpenUrl";

interface IDiverseCardProps {
  cardList?: IBannerList[];
  footer?: IDiverseCardFooter;
}

const columns = 3;
/** 多个卡片  */
const DiverseCard: React.FC<IDiverseCardProps> = (props) => {
  const { cardList, footer } = props;
  const openUrl = useOpenUrl();
  const showCardList = useMemo(() => {
    if (!cardList?.length) return [];
    return chunk(cardList, columns);
  }, [cardList]);
  return (
    <Space block direction="vertical" className={styles.card}>
      {showCardList.map((item, index) => (
        <Grid className={styles.row} columns={columns} key={index}>
          {item.map((card, cIndex) => (
            <Grid.Item
              className={styles.col}
              key={cIndex}
              onClick={() => card.link && openUrl(card.link)}
            >
              <div
                className={styles.image}
                style={{ backgroundImage: `url(${card.imageUrl})` }}
              >
                <div className={styles.title} style={card.titleStyle}>
                  {card.title}
                </div>
                <div className={styles.subTitle} style={card.subTitleStyle}>
                  {card.subTitle}
                </div>
              </div>
            </Grid.Item>
          ))}
        </Grid>
      ))}
      <div
        className={styles.footer}
        onClick={() => {
          footer?.link && openUrl(footer?.link);
        }}
      >
        <Image
          src={footer?.imageUrl}
          fit="cover"
          className={styles.footerIcon}
        />
        <div className={styles.text}>
          <Ellipsis content={footer?.text || ""} />
        </div>
      </div>
    </Space>
  );
};

export default DiverseCard;
