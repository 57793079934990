import React, { FC, useEffect, useMemo, useState } from "react";

import { Swiper, Image, Popup, List, Grid, Empty, Button } from "antd-mobile";
import { PhoneFill, RightOutline } from "antd-mobile-icons";
import Icon from "src/common/Component/Icon";

import styles from "./index.module.scss";
import dd from "dingtalk-jsapi";
import { useRequest } from "ahooks";
import { getShopDetail } from "../../service/getShopDetail";
import { useSearchParams } from "react-router-dom";
import { getShopRecommend } from "../../service/getShopRecommend";
import PageLoading from "@/common/Component/PageLoading";
import StoreCard from "../../components/StoreCard";
import { useOpenUrl } from "@/hooks/useOpenUrl";
import { ShopRecord } from "../../service/shopPageQuery";
import { getDiscount } from "../../static";
import { getLocation } from "@/services/dd/getLocation";

const ShopDetail: FC = () => {
  const env = {
    isAndroid: /android/i.test(navigator.userAgent),
    isIOS: /iphone|ipad|ipod/i.test(navigator.userAgent),
  };
  const { data: addressData } = useRequest(getLocation);

  const [visible, setVisible] = useState(false);
  const openUrl = useOpenUrl();
  const openGaoDeMap = () => {
    let url = "";
    if (env.isAndroid) {
      url = `androidamap://viewMap?sourceApplication=appname&poiname=${address}&lat=${lat}&lon=${lng}&dev=0`;
    } else if (env.isIOS) {
      url = `iosamap://viewMap?sourceApplication=appname&poiname=${address}&lat=${lat}&lon=${lng}&dev=0`;
    }
    dd.openLink({
      url,
    });

    setTimeout(function () {
      if (!document.hidden) {
        //调用高德地图
        window.location.href =
          "https://uri.amap.com/marker?position=" +
          lng +
          "," +
          lat +
          "&name=" +
          address;
      }
    }, 2000);
  };

  const openBaiDuMap = () => {
    let url = "";
    if (env.isAndroid) {
      // url = `baidumap://map/marker?location=${lat},${lng}&title=${address}&content=${address}&src=yourCompanyName|yourAppName`
      url = `baidumap://map/direction?destination=name:${address}|latlng:${lat},${lng}&coord_type=gcj02&mode=driving&src=andr.jianghu.jianhao`;
    } else if (env.isIOS) {
      url = `baidumap://map/direction?destination=name:${address}|latlng:${lat},${lng}&coord_type=gcj02&mode=driving&src=andr.jianghu.jianhao`;
      // url = `baidumap://map/marker?location=${lat},${lng}&title=${address}&content=${address}&src=yourCompanyName|yourAppName`
    }
    const d = new Date();
    const t0 = d.getTime();

    dd.openLink({
      url,
    });

    let delay = setInterval(function () {
      const d = new Date();
      const t1 = d.getTime();
      if (t1 - t0 < 3000 && t1 - t0 > 2000) {
        window.location.href =
          "http://api.map.baidu.com/marker?location=" +
          lat +
          "," +
          lng +
          "&title=" +
          address +
          "&content=景点&output=html&src=webapp.baidu.openAPIdemo";
      }
      if (t1 - t0 >= 3000) {
        clearInterval(delay);
      }
    }, 1000);
  };
  const [searchParams] = useSearchParams();
  const shopId = searchParams.get("id") as string | undefined;

  const {
    run: runRecommend,
    loading: recommendLoading,
    data: shopRecommendData,
  } = useRequest(getShopRecommend, {
    manual: true,
  });
  const {
    run: runDetail,
    loading,
    data: detailData,
  } = useRequest(getShopDetail, {
    manual: true,
  });
  useEffect(() => {
    shopId && runDetail({ shopId });
  }, [shopId]);

  const { addressStr, images, discount, address, lat, lng } = useMemo(() => {
    return {
      addressStr: detailData?.data?.shopAddress,
      images: detailData?.data?.scenePic || [],
      discount: getDiscount(detailData?.data?.discountRate || 0),
      address: `---${detailData?.data?.shopAddress || ""}`,
      lat: detailData?.data?.shopLatitude || 0,
      lng: detailData?.data?.shopLongitude || 0,
    };
  }, [detailData]);
  useEffect(() => {
    if (detailData?.data?.shopLongitude && detailData?.data?.shopLatitude) {
      runRecommend({
        latitude: detailData?.data?.shopLatitude.toString(),
        longitude: detailData?.data?.shopLongitude.toString(),
        shopId: shopId,
        radiusInKm: "3.0",
      });
    }
  }, [detailData]);
  const { showData, emptyShow } = useMemo(() => {
    const data: Array<ShopRecord[]> = [[], []];
    shopRecommendData?.data?.forEach((item, index) => {
      data[index % 2].push(item);
    });
    return { showData: data, emptyShow: !shopRecommendData?.data?.length };
  }, [shopRecommendData]);
  return (
    <PageLoading loading={loading}>
      <div className={styles.container}>
        <Swiper
          className={styles.swiper}
          indicator={(total, current) => (
            <div className={styles.customIndicator}>
              {`${current + 1}/${total}`}
            </div>
          )}
        >
          {images.map((image, index) => (
            <Swiper.Item key={index}>
              <Image className={styles.image} src={image} fit="cover" />
            </Swiper.Item>
          ))}
        </Swiper>

        <div className={styles.content}>
          <div className={styles.restaurantInfo}>
            <h1 className={styles.restaurantName}>
              {detailData?.data?.shopName}
            </h1>
          </div>

          <div className={styles.addressSection}>
            <div>
              <div className={styles.addressHeader}>
                <span>{detailData?.data?.shopAddress}</span>
                <RightOutline className={styles.rightArrow} />
              </div>
              {/* <div className={styles.addressDetail}>{addressStr}</div> */}
            </div>
            <div className={styles.actionButtons}>
              <div
                onClick={() => {
                  setVisible(true);
                }}
              >
                <Icon icon="icon-dachezuche" className={styles.PhoneFill} />
                <div className={styles.actionButtonText}>导航</div>
              </div>
              <div>
                <a href={`tel:${detailData?.data?.shopMobile}`}>
                  <PhoneFill className={styles.PhoneFill} />
                  <div className={styles.actionButtonText}>电话</div>
                </a>
              </div>
            </div>
          </div>

          <Popup
            visible={visible}
            onMaskClick={() => {
              setVisible(false);
            }}
          >
            <List className={styles.popup}>
              <List.Item onClick={openGaoDeMap} clickable={false}>
                高德地图
              </List.Item>
              <List.Item onClick={openBaiDuMap} clickable={false}>
                百度地图
              </List.Item>
              <List.Item
                onClick={() => {
                  setVisible(false);
                }}
                clickable={false}
              >
                关闭
              </List.Item>
            </List>
          </Popup>
        </div>
        <div className={styles.payOrder}>
          <div className={styles.payOrderLeft}>
            <Image
              className={styles.payImage}
              src="https://cdn.wuhuxianmai.cn/feed/1736330270623-0df83a7f728bd0e01fe106400ce6ff6c85abeb239e65ec71704c1b2c0ea07dec"
            />
            <span className={styles.payOrderLeftText}>
              企业员工专享价：{getDiscount(detailData?.data?.discountRate || 0)}
              折
            </span>
          </div>
          <Button
            onClick={() => {
              openUrl({
                url: "/payOrder",
                isRoute: true,
                params: {
                  id: detailData?.data?.shopId,
                },
              });
            }}
            className={styles.payOrderRight}
            color="primary"
          >
            立即买单
          </Button>
        </div>
        <div className={styles.storeList}>
          <div className={styles.title}>推荐</div>
          <>
            <Grid columns={2} className={styles.productGrid}>
              {showData.map((item, index) => {
                return (
                  <Grid.Item key={index}>
                    <Grid columns={1}>
                      {item.map((li, iIndex: number) => {
                        return (
                          <Grid.Item
                            key={iIndex}
                            className={styles.productGridItem}
                          >
                            <StoreCard
                              coverUrl={li.doorFrontPic}
                              title={li.shopName}
                              tag={`${getDiscount(li.discountRate)}折`}
                              onClick={() => {
                                openUrl({
                                  url: "/arriveStore/detail",
                                  isRoute: true,
                                  params: {
                                    id: li.shopId,
                                  },
                                });
                              }}
                            />
                          </Grid.Item>
                        );
                      })}
                    </Grid>
                  </Grid.Item>
                );
              })}
            </Grid>
            {/* <InfiniteScroll loadMore={loadMore} hasMore={hasMore} /> */}
          </>
          {emptyShow && <Empty description="暂无数据" />}
        </div>
      </div>
    </PageLoading>
  );
};

export default ShopDetail;
