import apiClient from "src/common/Utils/APIClient";

const getBuyerPay = async(params: any): Promise<any> => {

  const { payOrderId = 1 } = params || {};
  const res = await apiClient.get("/dmall_payment/buyerPay", {
    param: {
      payOrderId,
    },
  });
  const { data = {} } = res || {};
  return res;
};

export { getBuyerPay };
