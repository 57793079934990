import { Toast } from "antd-mobile";
import * as dd from "dingtalk-jsapi";
import { openAuth } from "dingtalk-design-libs";
import { getENV } from "dingtalk-jsapi/lib/env";
import { compareVersion } from "dingtalk-jsapi/lib/sdk/sdkLib";

// const APP_ID = process.env.REACT_APP_WU_HU_LOCAL_LIFE_APP_KEY;
const { platform, version, appType } = getENV();

export interface AppAuthCode {
  status?: string;
  result?: AppAuthCodeResult;
}

export interface AppAuthCodeResult {
  status?: boolean;
  authCode?: string;
}

/**
 * 接口权限
 */
export const userDingDingAuthRpcScope = {
  /**
   * 通讯录个人信息读权限
   */
  ContactUserRead: "Contact.User.Read",
};
/**
 * 字段权限
 */
export const userDingDingAuthFieldScope = {
  /**
   * 个人手机号信息
   */
  ContactUserMobile: "Contact.User.mobile",
};

export interface IResult {
  message?: string;
  success?: boolean;
  authCode?: string;
}
export interface IOpenAuthDialogParams {
  /**
   * 组织id
   */
  corpId: string;
  rpcScope?: string;
  fieldScope?: string;
  /**
   *  0 标识授权个人信息；1 标识授权组织信息
   */
  type: 0 | 1;
  from?: string;
  clientId: string;
}
/**
 * 打开授权弹窗
 */
export const openAuthDialog = (
  data: IOpenAuthDialogParams
): Promise<IResult> => {
  return new Promise((resolve, reject) => {
    if (dd?.env?.platform === "notInDingTalk") {
      reject({ success: false, message: "请在钉钉客户端内打开" });
      return;
    }
    // if (!APP_ID) {
    //   reject({ success: false, message: "请先配置APP_ID" });
    //   return;
    // }
    dd.ready(async () => {
      if (version && !compareVersion(version, "6.3.35")) {
        reject({
          success: false,
          message: "当前钉钉版本过低，请升级到6.3.35以上",
        });
        return;
      }
      const authCodeObj: AppAuthCode = await openAuth({
        ...data,
        // clientId: APP_ID, // 应用ID(唯一标识)
        // corpId: data.corpId, // 当前组织的corpId
        // rpcScope: "Contact.User.Read",
        // fieldScope: "Contact.User.mobile",
        // type: "0", // 0 标识授权个人信息；1 标识授权组织信息
      })
        .then((res: any) => res)
        .catch((e) => {
          console.log("e3", e);
          return {};
        });
      const authCode = authCodeObj?.result?.authCode;
      if (authCode) {
        resolve({ success: true, authCode });
      } else {
        reject({ success: false, message: "授权失败" });
      }
    });
  });
};
