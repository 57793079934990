import axios, { IBaseRes } from "@/common/Utils/APIClient";

/**
 * 商家更新订单号、折扣、费率
 */
export const shopUpdateRate = (params: IReq): Promise<IBaseRes<boolean>> => {
  return axios.post("/dmall_customer/tot/shop/updateRate", params);
};
interface IReq {
  /**
   * 店铺id
   */
  elmShopNo: string; // 商户id
  /**
   * 订单号
   */
  shopAlipayOrders?: string;
  /**
   * 折扣比例
   */
  shopRate?: number;
  /**
   * 佣金比例
   */
  shopCommission?: number;
  /**
   * 合作方类型
   */
  shopPartner?: 1 | 2;
  /**
   * 支付宝账号
   */
  shopAlipayAccount?: string;
  shopFirstCatId: number; // 一级类目
  shopFirstCatName: string; // 一级类目名
  shopSecondCatId: number; // 二级类目名
  shopSecondCatName: string; // 二级类目名
}
