import React from "react";
import { useRequest } from "ahooks";
import { Button, Toast } from "antd-mobile";
import { place4shop } from "./Services";
import { useOpenUrl } from "@/hooks/useOpenUrl";


function TriggerPay() {
  const openUrl = useOpenUrl();

  const { run } = useRequest(place4shop, {
    
    manual: true,
    onSuccess: (res: any) => {
      console.log(res)
      Toast.show({
        content: '支付成功'
      })
      window.open(`/pay?payOrderId=${res.data}`, '_blank');
      // openUrl({
      //   isRoute: true,
      //   url: "/pay",
      //   params: {
      //     payOrderId: res.data,
      //   }
      // })
    },
    onError: (err: any) => {
      Toast.show({
        content: '支付失败'
      })
    }
  });

  const doPay = () => {
    run({
      shopId: "1239283472874928345",
      buyAmount: 14500,
      payAmount: 13400,
    });
  };

  return (
    <div>
      <Button onClick={doPay}>支付</Button>
    </div>
  );
}

export default TriggerPay;