import axios, { IBaseRes } from "src/common/Utils/APIClient";

export interface IParamData {
  shopId: string;
  buyAmount: number;
  payAmount: number;
  remark?: string;
}

export const place4shop = (
  param?: IParamData
): Promise<IBaseRes<any>> => {
  return axios.post("/dmall_payment/buyerPay/place4shop", param );
};