import React, { RefObject, useEffect, useMemo, useState } from "react";
import { Cascader, CascaderRef, Form, ImageUploader, Toast } from "antd-mobile";
import get from "lodash/get";
import FormItem from "@/common/Component/FormItem";
import usePageTitle from "@/hooks/usePageTitle";
import Buttons from "@/common/Component/Button";
import styles from "./index.module.scss";
import FormCardTitle from "../../../../common/Component/FormCardTitle";
import { useOpenUrl } from "@/hooks/useOpenUrl";
import { useRequest } from "ahooks";
import { useSearchParams } from "react-router-dom";
import ApplyLayout from "@/common/Component/ApplyPage";
import { findTree, useCateTree } from "../../service/getCateTree";
import classNames from "classnames";
import PageLoading from "@/common/Component/PageLoading";
import { shopUpdateCat } from "../../service/shopUpdateCat";
import useArriveStore from "@/store/arriveStore";
import { useShallow } from "zustand/react/shallow";
import { getFromElmShopElmDetail } from "../../service/getFromElmShopElmDetail";
interface IConfirmProps {}

/**  商户签约-信息确认 */
const Confirm: React.FC<IConfirmProps> = (props) => {
  const {} = props;
  usePageTitle({ title: "商户签约到店服务" });
  const openUrl = useOpenUrl();
  const [form] = Form.useForm();
  const [setRegisterShopId, setRegisterStoreCallbackUrl] = useArriveStore(
    useShallow((state) => [
      state.setRegisterShopId,
      state.setRegisterStoreCallbackUrl,
    ])
  );
  const [searchParams] = useSearchParams();
  const shopId = searchParams.get("shopId");
  const callback = searchParams.get("callbackurl");
  const { selectTree } = useCateTree();
  const { run: shopUpdateRun, loading: updateLoading } = useRequest(
    shopUpdateCat,
    {
      manual: true,
      onSuccess: (res) => {
        if (res.success && res.data) {
          Toast.show({
            icon: "success",
            content: "提交成功",
          });
          openUrl({
            isRoute: true,
            url: "/open/arriveStore/merchant/protocol",
          });
        } else {
          Toast.show({
            icon: "fail",
            content: res.msg || "提交失败",
          });
        }
      },
    }
  );
  const {
    run: getShopDetailRun,
    data: shopElmDetail,
    loading: detailLoading,
  } = useRequest(getFromElmShopElmDetail, {
    // defaultParams: [{ shopId }],
    manual: true,
  });
  useEffect(() => {
    if (!shopId) {
      Toast.show({
        icon: "fail",
        content: "未找到商家ID",
      });
      return;
    }
    getShopDetailRun({ shopId });
    setRegisterShopId(shopId);
    setRegisterStoreCallbackUrl(callback || undefined);
  }, []);

  const onFinish = (values: any) => {
    if (!shopId) {
      Toast.show({
        icon: "fail",
        content: "缺少店铺id",
      });
      return;
    }
    const data = findTree(selectTree, values.category || []);
    shopId &&
      shopUpdateRun({
        elmShopNo: shopId,
        shopFirstCatId: get(data, "[0].value"), // 一级类目
        shopFirstCatName: get(data, "[0].label"), // 一级类目名
        shopSecondCatId: get(data, "[1].value"), // 二级类目名
        shopSecondCatName: get(data, "[1].label"), // 二级类目名
      });
  };
  const address = useMemo(() => {
    return `${get(shopElmDetail, "data.provinceName", "")}${get(
      shopElmDetail,
      "data.cityName",
      ""
    )}${get(shopElmDetail, "data.districtName", "")}${get(
      shopElmDetail,
      "data.shopAddress",
      ""
    )}`;
  }, [shopElmDetail]);
  const [selectIsLeaf, setSelectIsLeaf] = useState<boolean>(false);
  return (
    <PageLoading loading={detailLoading}>
      <ApplyLayout>
        <Form
          layout="horizontal"
          onFinish={onFinish}
          mode="card"
          className={styles.form}
          form={form}
          onValuesChange={(_, values) => {
            console.log("valueChange", values, _);
          }}
        >
          <Form.Header />
          <FormCardTitle title="确认店铺信息" />
          <FormItem
            label="店铺名称"
            // name="discount"
            // rules={[{ required: true, message: "必填" }]}
          >
            {shopElmDetail?.data?.shopName}
          </FormItem>
          <FormItem
            label="经营类目"
            name="category"
            onClick={(e, ref: RefObject<CascaderRef>) => {
              setSelectIsLeaf(false);
              ref.current?.open();
            }}
            required
            rules={[{ required: true, message: "必填" }]}
            trigger="onConfirm"
          >
            <Cascader
              // @ts-ignore
              options={selectTree}
              className={classNames(styles.cascader, {
                [styles.cascaderOk]: selectIsLeaf,
              })}
              onSelect={(value, extend) => {
                setSelectIsLeaf(extend.isLeaf);
              }}
            >
              {(value) => {
                if (!value?.length) {
                  return "请选择类目";
                }
                return (
                  <div>{value?.map((item) => item?.label)?.join("/")}</div>
                );
              }}
            </Cascader>
          </FormItem>
          <FormItem
            label="店铺地址"
            // name="address"
            // rules={[{ required: true, message: "必填" }]}
          >
            {address}
          </FormItem>
          <FormItem
            label="手机号"
            // name="phone"
            // rules={[{ required: true, message: "必填" }]}
          >
            {shopElmDetail?.data?.shopPhones}
          </FormItem>
          <FormItem
            label="营业时间"
            // name="time"
            // rules={[{ required: true, message: "必填" }]}
          >
            {shopElmDetail?.data?.flexbleServingTimeStrList}
          </FormItem>
          <FormItem
            label="门头照片"
            // name="image"
            // rules={[{ required: true, message: "必填" }]}
          >
            <ImageUploader
              style={{ "--cell-size": "90px" }}
              // value={fileList}
              // onChange={setFileList}
              value={[{ url: shopElmDetail?.data?.shopSignPic || "" }]}
              maxCount={1}
              deletable={false}
              upload={() =>
                new Promise((resolve) => {
                  resolve({ url: "" });
                })
              }
            />
          </FormItem>
          <FormItem
            label="门店详情"
            // name="detail"
            // rules={[{ required: true, message: "必填" }]}
          >
            <ImageUploader
              style={{ "--cell-size": "90px" }}
              // value={fileList}
              // onChange={setFileList}
              value={[{ url: shopElmDetail?.data?.shopDetailPics || "" }]}
              maxCount={1}
              deletable={false}
              upload={() =>
                new Promise((resolve) => {
                  resolve({ url: "" });
                })
              }
            />
          </FormItem>
        </Form>

        <div className={styles.footer}>
          <Buttons
            color="primary"
            block
            onClick={() => {
              form.submit();
            }}
            loading={updateLoading}
          >
            已确认，去签约
          </Buttons>
        </div>
      </ApplyLayout>
    </PageLoading>
  );
};

export default Confirm;
