import React from "react";
import DefaultInfo from "@/common/Component/DefaultInfo";
import useGlobalStore from "@/store/global";
import { useShallow } from "zustand/react/shallow";
import { homePageLink } from "@/common/Utils/client";

function NoMatch() {
  const [appType] = useGlobalStore(useShallow((state) => [state.appType]));
  
  return (
    <DefaultInfo
      imgSrc="https://citycdn.oss-cn-hangzhou.aliyuncs.com/images/404error.png"
      title="系统异常"
      btnText="返回首页"
      btnLink={homePageLink(appType)}
    />
  );
}

export default NoMatch;