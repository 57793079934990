import apiClient from "src/common/Utils/APIClient";
// import { API_HOST } from "src/common/Config/Constant";

const getData = (params: any) => {
  const { pageIndex, ...other } = params || {};
  return apiClient
    .get('/dmall_payment/buyerPay/page', {
      param: {
        ...other,
        records: 10,
        total: 100,
        size: 10,
        current: pageIndex,
        buyerId: 1,
      },
    })
    .then((res: any) => {
      const { data ={} } = res || {};

      return data;
    });
};

export { getData };
