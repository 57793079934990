import axios, { IBaseRes } from "@/common/Utils/APIClient";
export const uploadUrl = "/dmall_customer/tot/shop/confirmUpload";

interface IParams {
  cooperIds: string[];
  agencyIds:string[];
  /**
   * 店铺id
   */
  elmShopNo: string;
}

/**
 * 确认上传
*/
export const confirmUpload = (data: IParams): Promise<IBaseRes<string>> => {
  const formData1 = new FormData();

  formData1.append("elmShopNo", data.elmShopNo);
  formData1.append("cooperIds", data.cooperIds.join(":"));
  formData1.append("agencyIds", data.agencyIds.join(":"));
  return axios.post<string>(uploadUrl, formData1, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};
