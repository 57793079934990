import axios, { IBaseRes } from "@/common/Utils/APIClient";
import { useRequest } from "ahooks";
import { useMemo } from "react";

interface ICate {
  categoryName: string;
  children: ICate[];
  createAt: null;
  id: string;
  level: number;
  maxServiceChargeRate: number;
  minServiceChargeRate: number;
  parentId: string;
  status: number;
  tags: Tag[];
  updateAt: null;
}

interface Tag {
  createAt: null;
  groupId: string;
  id: string;
  remark: string;
  status: number;
  tagName: string;
  updateAt: null;
}

interface IRecord {
  label: string;
  value: string;
  children?: IRecord[];
  data?: ICate;
  isLeaf?: boolean;
}
/**
 * 获取商品分类树
 */
const getCateTree = (): Promise<IBaseRes<ICate[]>> => {
  return axios.get("/dmall-multishop/arrive/get/cats");
};

export const findTree = (
  data: IRecord[],
  values: string[]
): { label: string; value: string }[] => {
  const resArr: { label: string; value: string }[] = [];
  values.reduce((pre, cur, curIndex, arr) => {
    const findItem = pre.find((item) => item.value === cur);
    if (findItem) {
      resArr.push({ label: findItem.label, value: findItem.value });
    }
    return findItem?.children || [];
  }, data);
  return resArr;
};
/**
 * 获取分类路径,通过最后一级id，查找路径
 */
export const findTreePath = (data: IRecord[], value: string): string[] => {
  const path: string[] = [];

  const traverse = (node: IRecord, parentPath: string[]): boolean => {
    if (node.value === value) {
      path.push(...parentPath, node.value);
      return true;
    }
    if (node.children) {
      for (const child of node.children) {
        if (traverse(child, [...parentPath, node.value])) {
          return true;
        }
      }
    }
    return false;
  };

  for (const item of data) {
    if (traverse(item, [])) {
      break;
    }
  }

  return path;
};
const formatTree = (tree: ICate[]) => {
  return tree.map((item) => {
    const data: IRecord = {
      label: item.categoryName,
      value: item.id,
      data: item,
    };
    if (item?.children?.length) {
      data.isLeaf = false;
      data.children = formatTree(item.children);
    } else {
      data.isLeaf = true;
    }

    return data;
  });
};
/**
 * 获取分类树
 */
export const useCateTree = () => {
  const { data, loading } = useRequest(getCateTree);
  const selectTree = useMemo(() => {
    const tree = data?.data || [];
    return formatTree(tree);
  }, [data]);
  return {
    selectTree,
    loading,
    data,
  };
};
