import apiClient from "src/common/Utils/APIClient";

interface IRes {
  code: string;
  msg: string;
  data: any;
  success: boolean;
}

interface IParams {
  unionCode: string;
}

const getPartnerCode = (params: IParams): Promise<IRes> => {
  return apiClient.get("/dmall-customer/partner/ua/detail", { param: params });
};

export { getPartnerCode };