import { useNavigate, NavigateOptions } from "react-router-dom";
import queryString from "query-string";
import { useCallback } from "react";
import { Toast } from "antd-mobile";
import { openHalfScreenH5 } from "@/services/dd/openLink";

export interface IOpenUrlParams<T = any> {
  /**
   * 跳转链接
   */
  url?: string;
  /**
   * 提示信息
   */
  message?: string;
  /**
   * 是否使用浏览器原生跳转,还是使用路由跳转,默认非路由跳转
   */
  isRoute?: boolean;
  /**
   * 是否浮层打开，默认外链浮层打开
   */
  isOpenHalf?: boolean;
  /**
   * 跳转参数
   */
  params?: T;
  /**
   * 跳转参数, navigate的参数
   */
  options?: NavigateOptions;
}
export const useOpenUrl = () => {
  const navigate = useNavigate();
  const openUrl = useCallback(
    <T>(data?: IOpenUrlParams<T>) => {
      if (!data) return;
      const {
        url,
        isRoute = false,
        params,
        options,
        message = "功能暂未开放,敬请期待",
        isOpenHalf = true,
      } = data;
      if (!url) {
        Toast.show({
          content: message,
          duration: 2000,
        });
        return;
      }
      if (isRoute) {
        navigate(
          `${url}?${queryString.stringify(params || {}, { encode: true })}`,
          options
        );
        return;
      } else if (isOpenHalf && !isRoute) {
        const height = 83;
        openHalfScreenH5(
          `dingtalk://dingtalkclient/action/im_open_hybrid_panel?panelHeight=percent${height}&hybridType=online&pageUrl=${encodeURIComponent(
            url
          )}`
        );
        return;
      } else {
        window.location.href = url;
      }
    },
    [navigate]
  );
  return openUrl;
};
