import * as dd from "dingtalk-jsapi";

interface IData {
  /**
   * 隐藏导航栏,ture,展示false;
   */
  hidden: boolean;
  onSuccess?: () => void;
  onFail?: () => void;
}
/**
 * 钉钉隐藏导航栏
 * @param data
 */
export const ddHideBar = (data: IData) => {
  if (dd.env.platform !== "notInDingTalk") {
    dd.ready(() => {
      dd.biz.navigation.hideBar(data);
    });
  }
};
