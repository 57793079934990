import axios from "@/common/Utils/APIClient";
export interface IApplyReq {
  /**
   * 申请入驻的组织id
   */
  applyOrgId: string;
}
/**
 * 审核状态
 */
export enum ApplyStatusEnum {
  /**
   * 不通过
   */
  REFUSE = 0,
  /**
   * 通过
   */
  PASS = 1,
}
/**
 * 企业状态
 */
export enum StatusEnum {
  /**
   * 待审核
   */
  PendingPlatformReview = 10,
  /**
   * 审核不通过
   */
  ReviewFailed = 30,
  /**注销*/
  Cancelled = 40,
  /**
   * 作废
   */
  Void = 50,
  /**
   * 审核通过
   */
  ReviewPassed = 60,
  /**
   * 账簿已开通
   */
  AccountBookOpened = 70,
  /**
   * 账簿已冻结
   */
  AccountBookBlock = 80,
}
export interface IApplyRes {
  code: string;
  data: IApplyResData;
  msg: string;
  success: boolean;
}

export interface IApplyResData {
  /** 企业id */
  enterpriseId: number;
  /** 企业编码 */
  enterpriseCd: string;
  /** 企业名称 */
  enterpriseName: string;
  /** 社会信用代码 */
  creditCode: string;
  /** 营业执照 */
  creditCert: string;
  /** 管理员姓名 */
  adminName: string;
  /** 管理员手机号 */
  adminMobile: string;
  /** 联系人 */
  contactName: string;
  /** 手机号 */
  contactPhone: string;
  /** 联系人证件 */
  contactCert: string;
  /** 餐补账户ID */
  accountId: number;
  /** 餐补卡号 */
  bankCardNo: string;
  /** 注册组织/员工ID */
  applyOrgId: string;
  /** 注册时间 */
  applyTime: string;
  /** 申请状态 */
  applyStatus: ApplyStatusEnum;
  /**
   * 申请单备注
   */
  applyNote: string;
  /**
   * 扩展信息
   */
  extInfo: string;
  /**
   * 审核时间
   */
  reviewTime: string;
  /**
   * 审核人
   */
  reviewBy: number;
  /**
   * 状态
   */
  status: StatusEnum;
  /**
   * 创建时间
   */
  createAt: string;
  /**
   * 更新时间
   */
  updateAt: string;
  /**
   * 租户ID
   */
  tanentId: number;
  /**
   * 创建人
   */
  createBy: number;
  /**
   * 更新人
   */
  updateBy: number;
}

/**
 * 通过组织id查询详情
 */
export const enterpriseApplyGet = (data: IApplyReq): Promise<IApplyRes> => {
  return axios.get("/dmall-account/Enterprise/apply", { param: data });
};
