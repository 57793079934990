import axios, { IBaseRes } from "@/common/Utils/APIClient";
import { ShopRecord } from "./shopPageQuery";

/**
 * 获取推荐商户列表
 */
export const getShopRecommend = (
  params: IReq
): Promise<IBaseRes<ShopRecord[]>> => {
  return axios.get("/dmall_multishop/arrive/recommend", {
    param: params,
  });
};
interface IReq {
  /**
   * 经度
   */
  longitude: string;
  /**
   * 纬度
   */
  latitude: string;
  shopId?: string;
  /**
   * 半径,单位公里
   */
  radiusInKm?: string;
}
