import { useRequest } from "ahooks";
import { Toast } from "antd-mobile";
import * as dd from "dingtalk-jsapi";
import { getDingConfig } from "./dingConfig";
import useGlobal from "@/store/global";
import { useShallow } from "zustand/react/shallow";
import { useEffect } from "react";
import { getLocation } from "./getLocation";

/**
 * 钉钉JSAPI配置
 */
export const useDDJSAPIConfig = () => {
  const [corpId, setDingLocation] = useGlobal(
    useShallow((state) => [state.corpId, state.setDingLocation])
  );
  const data = useRequest(getDingConfig, {
    onSuccess: (res) => {
      if (dd?.env?.platform !== "notInDingTalk") {
        dd.config({
          ...(res?.result || {}),
          jsApiList: ["getLocation", "device.geolocation.get"],
        });
        dd.error(function (error) {
          Toast.show({
            icon: "fail",
            content: JSON.stringify(error),
          });
        });
        setDingLocation();
      }
    },
    manual: true,
    // defaultParams: [{ url: window.location.href, corpId }],
  });
  const { run } = data;
  useEffect(() => {
    corpId && run({ url: window.location.href, corpId });
  }, [corpId]);
  return data;
};
