import React, { useEffect } from "react";
import { RouterProvider } from "react-router-dom";
import router from "./router";
import { disableIOSWebView } from "./services/dd/webView";
import globalStore from "@/store/global";
import { useShallow } from "zustand/react/shallow";
import { useSetCorpId } from "./hooks/useSetCorpId";
import { useHideDingRight } from "./hooks/useHideDingRight";
interface IAppProps {}

/** 根应用  */
const App: React.FC<IAppProps> = (props) => {
  const {} = props;
  const [setAppType, setClientId] = globalStore(
    useShallow((state) => [state.setAppType, state.setClientId])
  );
  useSetCorpId();
  useHideDingRight();
  useEffect(() => {
    disableIOSWebView();
    setAppType();
    setClientId();
  }, []);

  return <RouterProvider router={router} />;
};

export default React.memo(App);
