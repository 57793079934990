import React from "react";
import { useSearchParams } from "react-router-dom";
import { CheckCircleFill } from "antd-mobile-icons";
import { useRequest } from "ahooks";
import Content from "./Component/Content";
import styles from "./index.module.scss";
import { detailByNo } from "./Services";

function PayResult() {
  const [params] = useSearchParams();  

  const { data } = useRequest(
    () => detailByNo({
      tradeNo: params.get("out_trade_no") || "",
    })
  );

  return (
    <div className={styles.app}>
      <div className={styles.success}>
        <CheckCircleFill className={styles.fill} />
        <span>支付成功</span>
      </div>
      <div className={styles.sub}>感谢您的购买，欢迎下次光临</div>
      <Content data={data} />
    </div>
  );
}

export default PayResult;
