import Decimal from "decimal.js";

/**
 * banner数据
 */
export const bannerList = [
  {
    linkUrl: "https://www.baidu.com",
    imgUrl:
      "https://cdn.wuhuxianmai.cn/feed/1732157764939-8e8532407fc0afdf528fee64f59f36a3ffd0a372bbcb6a83037712b65a570204",
  },
];
/**
 * 获取折扣
 * @param num 小于1的小数
 * @returns 折扣
 */
export const getDiscount = (num: string | number) => {
  return new Decimal(num || 0).mul(10).toNumber();
};
