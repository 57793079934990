import React from "react";
import { useOpenUrl } from "@/hooks/useOpenUrl";
import { List } from "antd-mobile";
import { formatCurrency } from "@/common/Utils/money";
import styles from "./index.module.scss";
import dayjs from "dayjs";

export interface IBill {
  createAt: string;
  subject: string;
  payAmount?: number;
  orderId: string;
  refundAmount: number;
  refundStatus: number;
  orderType: string;
}

export interface IBillList {
  title: string;
  list: IBill[];
}

function BillList({ billList }: { billList: IBillList[] }) {
  const openUrl = useOpenUrl();

  const formatMonthTitle = (date: string) => {
    const currentMonth = dayjs().format('YYYY-MM');
    return date === currentMonth ? '本月' : dayjs(date).format('YYYY年MM月');
  };

  const renderRefundText = (order: IBill) => {
    if (order.refundStatus > 0) {
      return order.payAmount !== order.refundAmount ? 
        <div className={styles.refundText}>已退款(¥{formatCurrency(order.refundAmount)})</div> : 
        <div className={styles.refundText}>已全额退款</div>;
    }
    return null;
  };

  const renderExtra = (order: IBill) => {
    if (order.orderType === 'p') {
      return (
        <div className={styles.billAmount}>
          -{formatCurrency(order.payAmount || 0)}
          {renderRefundText(order)}
        </div>
      )
    } else if (order.orderType === 'r') {
      return (
        <div className={[styles.billAmount, styles.refundAmount].join(' ')}>
          +{formatCurrency(order.refundAmount || 0)}
        </div>
      )
    }
    return null;
  };

  return (
    <div className={styles.container}>
      {billList.map((group) => (
        <div key={group.title} className={styles.billGroup}>
          <List
            style={{
              '--border-inner': 'none',
            }}
            className={styles.billList}
            mode="card"
          >
            <List.Item className={styles.billFirstItem}>
              <div className={styles.billMonth}>{formatMonthTitle(group.title)}</div>
            </List.Item>
            {group.list.map((item, index) => (
              <List.Item
                arrow={false}
                key={`${group.title}-${index}`}
                className={styles.billItem}
                prefix={
                  <div className={styles.avatarByName}>
                    {item.subject.slice(0, 1)}
                  </div>
                }
                extra={renderExtra(item)}
                description={item.createAt}
                onClick={() => openUrl({
                  isRoute: true,
                  url: "/billDetail",
                  params: {
                    id: item.orderId,
                  }
                })}
              >
                <span className={styles.billSubject}>{item.subject}</span>
              </List.Item>
            ))}
          </List>
        </div>
      ))}
    </div>
  );
}

export default BillList;