import axios, { IBaseRes } from "@/common/Utils/APIClient";
import { ShopRecord } from "./shopPageQuery";
/**
 * 获取店铺详情
 */
export const getShopDetail = (params: IReq): Promise<IBaseRes<ShopRecord>> => {
  return axios.get("/dmall-multishop/arrive/shop/detail", {
    param: params,
  });
};
interface IReq {
  shopId: string;
}
