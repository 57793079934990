import React from "react";
import { Button, Toast } from "antd-mobile";
import { AddOutline } from "antd-mobile-icons";

import style from "./index.module.scss";
import { useOpenUrl } from "@/hooks/useOpenUrl";

function AddCard(props: any = {}) {
  const { text, type } = props;
  const openUrl = useOpenUrl();

  return (
    <div className={style.app}>
      <Button
        block
        color="primary"
        fill="none"
        className={style.button}
        onClick={() => {
          openUrl({
            url: `/openPay`,
            isRoute: true,
            params: { type },
          });
        }}
      >
        <AddOutline className={style.icon} /> {text}
      </Button>
    </div>
  );
}

export default AddCard;
