import apiClient from "src/common/Utils/APIClient";

interface IRes {
  code: string;
  msg: string;
  data: any;
  success: boolean;
}

interface IDeleteParams {
  id: string;
}

interface IListParams {
  staffName: string;
}

const getEmployeeList = (params?: IListParams): Promise<IRes> => {
  return apiClient.get("/dmall-customer/enterprise/staff/list", { param: params });
};

const deleteEmployee = (params: IDeleteParams): Promise<IRes> => {
  return apiClient.delete("/dmall-customer/enterprise/staff", { param: params });
};

export { getEmployeeList, deleteEmployee };