import React from "react";
import { Button, Form, Input, Radio, Toast } from "antd-mobile";

import get from "lodash/get";
import { Decimal } from "decimal.js";
import { useRequest } from "ahooks";

import FormItem from "@/common/Component/FormItem";
import usePageTitle from "@/hooks/usePageTitle";
import Buttons from "@/common/Component/Button";
import styles from "./index.module.scss";
import FormCardTitle from "../../../../common/Component/FormCardTitle";
import { DeleteOutline } from "antd-mobile-icons";
import { useOpenUrl } from "@/hooks/useOpenUrl";
import { shopUpdateRate } from "@/pages/ArriveStore/service/shopUpdateRate";
import ApplyLayout from "@/common/Component/ApplyPage";
import { useSearchParams } from "react-router-dom";
import useArriveStore from "@/store/arriveStore";
import { useShallow } from "zustand/react/shallow";

interface ISignContractInfoProps {}

/**  商户签约-收集商户信息 */
const SignContractInfo: React.FC<ISignContractInfoProps> = (props) => {
  const {} = props;
  usePageTitle({ title: "商户签约到店服务" });
  const openUrl = useOpenUrl();
  const [shopId] = useArriveStore(
    useShallow((state) => [state.registerShopId])
  );
  const [form] = Form.useForm();
  const { run: shopUpdateRun, loading: updateLoading } = useRequest(
    shopUpdateRate,
    {
      manual: true,
      onSuccess: (res) => {
        if (res.data && res.success) {
          Toast.show({
            icon: "success",
            content: "提交成功",
          });
          openUrl({
            isRoute: true,
            url: "/open/arriveStore/merchant/upload/protocol",
          });
        } else {
          Toast.show({
            icon: "fail",
            content: res.msg || "提交失败",
          });
        }
      },
    }
  );

  const onFinish = (values: any) => {
    console.log(values);
    const { shopAlipayOrders, shopRate, shopCommission, ...rest } = values;
    const shopAlipayOrdersStr: string = get(values, "shopAlipayOrders", [])
      ?.map((item: any) => item.id)
      ?.join(":");
    const shopRateStr = new Decimal(get(values, "shopRate", ""))
      .div(100)
      .toString();
    const shopCommissionStr = new Decimal(get(values, "shopCommission", ""))
      .div(100)
      .toString();
    shopUpdateRun({
      ...rest,
      elmShopNo: shopId,
      shopAlipayOrders: shopAlipayOrdersStr,
      shopRate: shopRateStr,
      shopCommission: shopCommissionStr,
    });
  };
  const shopPartner = Form.useWatch("shopPartner", form);
  return (
    <ApplyLayout>
      <Form
        initialValues={{
          shopAlipayOrders: [
            {
              id: "",
            },
          ],
        }}
        layout="horizontal"
        onFinish={onFinish}
        mode="card"
        className={styles.form}
        form={form}
        onValuesChange={(_, values) => {
          console.log(values, _);
        }}
      >
        <div className={styles.top}>
          <FormCardTitle
            title="收集商户信息（？）"
            subTitle="为确保折扣生效，请确认所有收款设备的支付"
          />
          <Form.Array
            name={"shopAlipayOrders"}
            onAdd={(operation) => {
              operation.add({ id: "" });
            }}
            renderAdd={() => (
              <Button className={styles.addButton} color="primary" fill="none">
                + 添加订单号
              </Button>
            )}
          >
            {(fields, operation) =>
              fields.map(({ index }) => (
                <FormItem
                  label="支付宝订单号"
                  name={[index, "id"]}
                  rules={[{ required: true, message: "必填" }]}
                >
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <Input clearIcon clearable placeholder="请输入订单号" />

                    {index !== 0 && (
                      <DeleteOutline
                        className={styles.delete}
                        onClick={() => operation.remove(index)}
                      />
                    )}
                  </div>
                </FormItem>
              ))
            }
          </Form.Array>
        </div>

        <Form.Header />
        <FormCardTitle title="商品折扣" />
        <FormItem
          label="商品折扣比例"
          name="shopRate"
          rules={[
            { required: true, message: "必填" },
            {
              pattern: /^(\d+)(\.\d{1,2})?$/,
              message: "只能输入正数，且小数点后只能保留两位",
            },
            {
              pattern: /^(100|[1-9]?[0-9])$/,
              message: "只能输入0~100之间的数字",
            },
          ]}
        >
          <div style={{ display: "flex" }}>
            <Input
              type="number"
              // max={100}
              // min={0}
              placeholder="请填写商品折扣比例"
            />
            <span>%</span>
          </div>
        </FormItem>
        <Form.Header />
        <FormCardTitle title="商品佣金" />
        <FormItem
          label="商品佣金比例"
          name="shopCommission"
          rules={[
            { required: true, message: "必填" },
            {
              pattern: /^(\d+)(\.\d{1,2})?$/,
              message: "只能输入正数，且小数点后只能保留两位",
            },
            {
              pattern: /^(100|[1-9]?[0-9])$/,
              message: "只能输入0~100之间的数字",
            },
          ]}
        >
          <div style={{ display: "flex" }}>
            <Input
              type="number"
              // max={100}
              // min={0}
              placeholder="请填写商品佣金比例"
            />
            <span>%</span>
          </div>
        </FormItem>
        <FormItem
          label="合作方"
          name="shopPartner"
          rules={[{ required: true, message: "必填" }]}
          initialValue={"1"}
        >
          <Radio.Group>
            <Radio value={"1"}>支付宝</Radio>
            <Radio value={"2"}>其他（收钱吧、银行...）</Radio>
          </Radio.Group>
        </FormItem>
        {shopPartner === "2" && (
          <FormItem
            preserve={false}
            label="支付宝账户"
            name="shopAlipayAccount"
            rules={[{ required: shopPartner === "2", message: "必填" }]}
          >
            <Input placeholder="请填写支付宝账户" />
          </FormItem>
        )}
      </Form>
      <div className={styles.footer}>
        <Buttons
          color="primary"
          block
          onClick={() => {
            form.submit();
          }}
          loading={updateLoading}
        >
          提交
        </Buttons>
      </div>
    </ApplyLayout>
  );
};

export default SignContractInfo;
