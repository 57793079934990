import React from "react";
import { BANK_HOST } from "src/common/Config/Constant";
import styles from "./index.module.scss";
import { Image } from "antd-mobile";

function CustomCard(props: any = {}) {
  const {
    title = "",
    wayCd = "",
    cardNumber = "",
    className = "",
    style = {},
  } = props;

  return (
    <div
      className={`${styles.app} ${className || ""}`}
      style={{
        ...style,
        // backgroundImage: `url('${BANK_HOST}/${wayCd}_bg.png')`,
      }}
    >
      <div className={styles.header}>
        {/* <div className={styles.icon}>
          <img src={`${BANK_HOST}/${wayCd}_icon.png`} alt="卡片" />
        </div> */}
        <Image
          className={styles.icon}
          src="https://cdn.wuhuxianmai.cn/feed/1732863023991-9461eefa9b19f0a23b166f412d5d0825ea819237eca6f034e2b7bde43aa2b246"
        />
        <div className={styles.title}>一卡通</div>
      </div>
      <div className={styles.content}>{cardNumber}</div>
    </div>
  );
}

export default CustomCard;
