import apiClient from "src/common/Utils/APIClient";
// import { API_HOST } from "src/common/Config/Constant";

const   getBuyerPay = (params: any): any => async (): Promise<any> => {
    const { payOrderId = 1 } = params || {};

    const res = await apiClient.get("/dmall_payment/buyerPay", {
      param: {
        payOrderId,
      },
    });
    const { data = {} } = res || {};
    const { payMethods = [], ...other } = data || {};
    const result = {
      onePay: { total: 0 },
      bankCard: { total: 0 },
      alipay: { total: 0 },
      wechat: { total: 0 },
      unionpay: { total: 0 },
    } as any;

    payMethods.forEach((el: any) => {
      const { methodCd, bankCards } = el || {};

      if (methodCd === "onepay") {
        result.onePay = { ...el, total: 1 };
      }

      if (methodCd === "bankcard") {
        result.bankCard = { ...el, total: bankCards.length };
      }

      if (methodCd === "alipay") {
        result.alipay = { ...el, total: 1 };
      }

      if (methodCd === "wechat") {
        result.wechat = { ...el, total: 1 };
      }

      if (methodCd === "unionpay") {
        result.unionpay = { ...el, total: 1 };
      }
    });
    return { ...other, payMethods: result };
  };

const confirmPay = async (params: any) => {
  const {
    payOrderId = 1,
    payMethodId,
    bankCardNo = "",
    payPassword,
  } = params || {};

  const res = await apiClient.post("/dmall_payment/buyerPay/comfirmPay", {
    payOrderId,
    payMethodId,
    bankCardNo,
    payPassword,
  });

  return res;
};

const accounts = async (params: any) => {

  const res = await apiClient.get("/dmall_account/Account/accounts");

  return res;
};

export { getBuyerPay, confirmPay, accounts };