import Buttons from "@/common/Component/Button";
import React, { useEffect, useMemo, useRef, useState } from "react";
import styles from "./index.module.scss";
import { Form, Input, Toast } from "antd-mobile";
import FormCardTitle from "@/common/Component/FormCardTitle";
import FormItem from "@/common/Component/FormItem";
import { phoneRegExp, socialCreditCodeRegExp } from "@/common/Config/regExp";
import Step from "../../components/Step";
import VerificationCode, {
  IRef,
} from "../../../../common/Component/VerificationCode";
import Result from "../../components/Result";
import { useRequest } from "ahooks";
import { enterpriseApply } from "../../services/enterpriseApply";
import useUserStore from "@/store/global";
import {
  enterpriseApplyGet,
  StatusEnum,
} from "../../services/enterpriseApplyGet";
import ApplyLayout from "@/common/Component/ApplyPage";

const requiredRuleObj = { required: true, message: "必填" };
enum StepEnum {
  /** 步骤1 */
  One = 1,
  /** 步骤2 */
  Two = 2,
  /** 步骤3 */
  Three = 3,
}
const stepDataSource = [
  {
    title: "提交入驻资料",
  },
  { title: "平台审核" },
  { title: "开通企业账户" },
];
interface IApplayProps {}

/** 企业入驻申请  */
const Applay: React.FC<IApplayProps> = (props) => {
  const {} = props;
  const ref = useRef<IRef>({});
  const {
    data: applyData,
    loading: getLoading,
    run: enterpriseApplyGetRun,
  } = useRequest(enterpriseApplyGet, {
    manual: true,
    onSuccess: (res) => {
      if (res.success && res.data) {
        switch (res.data.status) {
          case StatusEnum.PendingPlatformReview:
            setActiveStep(1);
            break;
          case StatusEnum.ReviewFailed:
            setActiveStep(2);
            break;
          case StatusEnum.Cancelled:
            setActiveStep(2);
            break;
          case StatusEnum.Void:
            setActiveStep(2);
            break;
          case StatusEnum.ReviewPassed:
            setActiveStep(2);
            break;
          case StatusEnum.AccountBookOpened:
            setActiveStep(2);
            break;
          case StatusEnum.AccountBookBlock:
            setActiveStep(2);
            break;
        }
      } else {
        setActiveStep(0);
      }
    },
  });
  const { run: enterpriseApplyRun, loading } = useRequest(enterpriseApply, {
    manual: true,
    onSuccess: (res) => {
      console.log("res", res);
      if (res.success) {
        cropId && enterpriseApplyGetRun({ applyOrgId: cropId });
        setActiveStep((activeStep || 0) + 1);
        Toast.show({
          icon: "success",
          content: "提交成功",
        });
      }
    },
  });
  const cropId = useUserStore((state) => state.corpId);
  const [form] = Form.useForm();
  const [activeStep, setActiveStep] = useState<number>();
  const [visible, setVisible] = useState(false);

  const onFinish = (values: any) => {
    setVisible(true);
    ref.current?.focus?.();
  };
  const okCode = () => {
    const values = form.getFieldsValue();
    enterpriseApplyRun({ ...values, applyOrgId: cropId });
  };

  useEffect(() => {
    cropId && enterpriseApplyGetRun({ applyOrgId: cropId });
  }, [cropId]);
  return (
    <ApplyLayout>
      <div className={styles.content}>
        <div className={styles.step}>
          <Step current={activeStep} dataSource={stepDataSource} />
        </div>
        {activeStep === 0 && (
          <Form
            form={form}
            className={styles.form}
            layout="horizontal"
            onFinish={onFinish}
            mode="card"
          >
            <Form.Header />
            <FormCardTitle title="企业基础信息" />
            <FormItem
              label="企业名称"
              name="enterpriseName"
              rules={[requiredRuleObj]}
            >
              <Input placeholder="请输入企业名称" />
            </FormItem>
            <FormItem
              label="社会信用代码"
              name="creditCode"
              rules={[
                requiredRuleObj,
                {
                  pattern: socialCreditCodeRegExp,
                  message: "请正确输入社会信用代码",
                },
              ]}
            >
              <Input placeholder="请输入社会信用代码" />
            </FormItem>
            <FormItem
              label="联系人"
              name="contactName"
              rules={[requiredRuleObj]}
            >
              <Input placeholder="请输入联系人" />
            </FormItem>
            <FormItem
              label="联系电话"
              name="contactPhone"
              rules={[
                requiredRuleObj,
                { pattern: phoneRegExp, message: "请输入正确的手机号" },
              ]}
            >
              <Input placeholder="请输入联系电话" />
            </FormItem>

            <Form.Header />
            <FormCardTitle title="餐补管理员" />
            <FormItem
              label="管理员姓名"
              name="adminName"
              rules={[requiredRuleObj]}
            >
              <Input placeholder="请输入管理员姓名" />
            </FormItem>
            <FormItem
              label="手机号码"
              name="adminMobile"
              rules={[
                requiredRuleObj,
                { pattern: phoneRegExp, message: "请输入正确的手机号" },
              ]}
            >
              <Input placeholder="请输入管理员手机号码" />
            </FormItem>
          </Form>
        )}
        {(activeStep === 1 || activeStep === 2) && (
          <Result
            data={applyData?.data}
            onReApply={() => {
              setActiveStep(0);
            }}
          />
        )}
        <VerificationCode
          visible={visible}
          ref={ref}
          phone={form.getFieldValue("contactPhone")}
          onClose={() => {
            setVisible(false);
          }}
          onOk={okCode}
        />
      </div>

      {activeStep === 0 && (
        <div className={styles.footer}>
          <Buttons
            color="primary"
            block
            onClick={() => {
              form.submit();
            }}
          >
            提交
          </Buttons>
        </div>
      )}
    </ApplyLayout>
  );
};

export default Applay;
