import React, { CSSProperties, useEffect, useMemo, useRef } from "react";
import styles from "./index.module.scss";
import {
  Ellipsis,
  Empty,
  Grid,
  Image,
  Skeleton,
  Space,
  Swiper,
} from "antd-mobile";
import { useRequest } from "ahooks";
import { getFavoritesList } from "../../service/getFavoritesList";
import get from "lodash/get";
import chunk from "lodash/chunk";
import { splitFavoriteTitle } from "src/common/Utils";
import { useOpenUrl } from "src/hooks/useOpenUrl";

interface IActivityCardProps {
  data: {
    /**
     * 活动ID-(收藏夹id)
     */
    activityId: number;
    /**
     * 收藏夹名称
     */
    title?: string;
    /**
     * 副标题
     */
    subTitle?: string;
    /**
     * 顶部背景图片
     */
    headeBackgroundImageUrl?: string;
    /**
     * 顶部背景色
     */
    headeBackgroundColor?: string;
  };
}

/** 活动卡片  */
const ActivityCard: React.FC<IActivityCardProps> = (props) => {
  const { data } = props;
  const cardRef = useRef<HTMLDivElement>(null);
  const {
    data: listData,
    loading,
    error,
    run: getFavoritesListRun,
  } = useRequest(getFavoritesList, {
    manual: true,
  });
  const openUrl = useOpenUrl();
  const headerStyle = useMemo(() => {
    const sObj: CSSProperties = {};
    if (data.headeBackgroundColor) {
      sObj.backgroundColor = data.headeBackgroundColor;
    }
    if (data.headeBackgroundImageUrl) {
      sObj.backgroundImage = `url(${data.headeBackgroundImageUrl})`;
    }
    return sObj;
  }, [data]);
  console.log("headerStyle", headerStyle);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting && !listData) {
            // console.log("展示出来啦");
            // 执行需要的操作，例如懒加载
            getFavoritesListRun({ favoriteId: data.activityId });
          }
        });
      },
      {
        threshold: 0.01, // 当元素至少 1% 可见时触发回调
      }
    );

    if (cardRef.current) {
      observer.observe(cardRef.current);
    }

    // 清理函数
    return () => {
      if (cardRef.current) {
        observer.unobserve(cardRef.current);
      }
    };
  }, [data, listData]);

  /*** 商品列表 */
  const product = useMemo(() => {
    const data =
      get(
        listData,
        "tbk_dg_material_recommend_response.result_list.map_data"
      ) || [];
    const columns = data?.length ? Math.ceil(data.length / 2) : 0;
    const swiperData = chunk(data, 6)?.map((item) => chunk(item, 3));

    return {
      data: chunk(data, columns),
      swiperData,
    };
  }, [listData]);
  const title = useMemo(() => {
    return splitFavoriteTitle(data.title || "");
  }, [data]);
  return (
    <div className={styles.card}>
      <div className={styles.header} style={headerStyle}>
        <div className={styles.title}>{title.title}</div>
        <div className={styles.subTitle}>{title.subTitle || data.subTitle}</div>
      </div>
      <div className={styles.content} ref={cardRef}>
        {!!product.swiperData.length && (
          <Swiper
            className={styles.swiper}
            indicatorProps={{ className: styles.customIndicator }}
          >
            {product.swiperData.map((item, index) => {
              return (
                <Swiper.Item key={index}>
                  {item.map((list, index2) => {
                    return (
                      <Grid columns={3} key={index2} className={styles.grid}>
                        {list.map((li, index3) => {
                          const price = get(
                            li,
                            "price_promotion_info.zk_final_price",
                            ""
                          );
                          const [yuan, jiao] = price.split(".");
                          return (
                            <Grid.Item key={index3} className={styles.gridItem}>
                              <div
                                key={index}
                                className={styles.productCard}
                                onClick={() => {
                                  if (li?.publish_info?.click_url) {
                                    openUrl({
                                      url: li.publish_info.click_url,
                                    });
                                  }
                                }}
                              >
                                <Image
                                  className={styles.image}
                                  fit="cover"
                                  src={li.item_basic_info.pict_url}
                                />
                                <Ellipsis
                                  content={li.item_basic_info.title}
                                  className={styles.title}
                                  rows={2}
                                />
                                {!!li?.item_basic_info?.annual_vol && (
                                  <div>已售{li.item_basic_info.annual_vol}</div>
                                )}
                                {!!li?.price_promotion_info?.zk_final_price && (
                                  <div className={styles.price}>
                                    <span className={styles.unit}>￥</span>
                                    <span className={styles.money}>{yuan}</span>
                                    {!!jiao && (
                                      <span className={styles.unit}>
                                        .{jiao}
                                      </span>
                                    )}
                                  </div>
                                )}
                              </div>
                            </Grid.Item>
                          );
                        })}
                      </Grid>
                    );
                  })}
                </Swiper.Item>
              );
            })}
          </Swiper>
        )}
        {!product.swiperData.length && !loading && (
          <Empty description="暂无数据" />
        )}
        {!product.swiperData.length && loading && (
          <Skeleton.Paragraph
            className={styles.paragraph}
            lineCount={20}
            animated
          />
        )}
      </div>
    </div>
  );
};

export default ActivityCard;
