import { ProtocolTypeEnum } from "../enum";
import axios, { IBaseRes } from "@/common/Utils/APIClient";
const uploadUrl = "/dmall_customer/tot/shop/uploadSingle";

export interface IUploadOneParams {
  file: File;
  /**
   * 协议类型
   */

  protocolType: ProtocolTypeEnum;
  /**
   * 店铺id
   */
  elmShopNo: string;
}

/**
 * 上传文件
 * @param data
 * @returns
 */
export const uploadShopOne = (data: IUploadOneParams): Promise<IBaseRes<IUploadOneRes>> => {
  const formData1 = new FormData();
  formData1.append("file", data.file, data.file.name);
  formData1.append("elmShopNo", data.elmShopNo);
  formData1.append("protocolType", data.protocolType.toString());
  return axios.post<string>(uploadUrl, formData1, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};
interface IUploadOneRes {
  elmShopNo: string;
  gmtCreate: Date;
  gmtModified: Date;
  id: number;
  ossUrl: string;
  picIndex: number;
  protocolType: number;
  status: number;
}