import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Form, Input, Button, Modal, Grid } from "antd-mobile";
import { useRequest } from "ahooks";
import usePageTitle from "@/hooks/usePageTitle";
import { addEmployee } from "./Services";

import styles from "./index.module.scss";


function EmployeeManagement() {
  usePageTitle({ title: "新增员工" });
  const navigate = useNavigate();
  const [visible, setVisible] = useState(false);
  const [form] = Form.useForm();

  const { run } = useRequest(addEmployee, {
    manual: true,
    onSuccess: (res) => {
      if (res.success) {
        setVisible(true);
      }
    },
    onError: (error) => {
      console.log(error)
    }
  }) 

  useEffect(() => {
  }, []);

  const onSubmit = async () => {
    try {
      const values = await form.validateFields();
      run(values)
    } catch (error) {
      console.log(error)
    }
  }

  const onContinue = () => {
    setVisible(false);
    form.resetFields();
  }

  const onBack = () => {
    navigate(-1);
  }
  
  return (
    <div className={styles.container}>
      <Form
        layout='horizontal'
        form={form}
        footer={
          <div className={styles.btnContainer}>
            <Button className={styles.btn} onClick={onSubmit} block size="large" type="submit">
              确认添加
            </Button>
          </div>
        }
      >
        <Form.Item
          label='姓名'
          name='staffName'
          validateTrigger={[]} // 禁用自动校验
          rules={[
            { required: true, message: '请输入员工姓名' },
            { max: 12, message: '姓名输入超长' }
          ]}
        >
          <Input placeholder='请输入员工姓名' maxLength={12} />
        </Form.Item>
        <Form.Item
          label='手机号'
          name='staffPhone'
          validateTrigger={[]} // 禁用自动校验
          rules={[
            { required: true, message: '请输入员工手机号' },
            { pattern: /^1[3-9]\d{9}$/, message: '手机号格式不正确' }
          ]}
        >
          <Input type="number" placeholder='请输入员工手机号' />
        </Form.Item>
      </Form>
      
      <Modal
        title='添加员工成功'
        visible={visible}
        content={
          <div className={styles.dialogContainer}>
            <Grid className={styles.dialogGrid} columns={2} gap={8}>
              <Grid.Item>
                <Button className={styles.continueBtn} block size="middle" onClick={onContinue}>
                  继续添加
                </Button>
              </Grid.Item>
              <Grid.Item>
                <Button className={styles.backBtn} block size="middle" onClick={onBack}>
                  返回
                </Button>
              </Grid.Item>
            </Grid>
          </div>
        }
      />
    </div>
  );
}

export default EmployeeManagement;