import React, { CSSProperties } from "react";
import classNames from "classnames";
import styles from "./index.module.scss";
import PageLoading from "../PageLoading";

interface IPageProps {
  loading?: boolean;
  children?: React.ReactNode;
  className?: string;
  style?: CSSProperties;
}

/** 页面容器  */
const Page: React.FC<IPageProps> = (props) => {
  const { children, loading, className, style } = props;
  if (loading) {
    return <PageLoading />;
  }
  return (
    <div className={classNames(styles.page, className)} style={style}>
      {children}
    </div>
  );
};

/** 页面容器项目-  */
export const PageItem: React.FC<{
  children: React.ReactNode;
  className?: string;
}> = (props) => {
  const { children, className } = props;

  return (
    <div className={classNames(styles.pageItem, className)}>{children}</div>
  );
};
export default Page;
