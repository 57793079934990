import React from "react";
import "./index.css";

interface IIconProps {
  icon?: string;
  className?: string;
  onClick?: () => void;
}
function Icon(props: IIconProps = {}) {
  const { icon, className = "", onClick } = props;
  return (
    <svg onClick={onClick} className={`icon ${className}`} aria-hidden="true">
      <use xlinkHref={`#${icon}`}></use>
    </svg>
  );
}

export default Icon;
