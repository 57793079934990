import ApplyLayout from "@/common/Component/ApplyPage";
import React from "react";
import styles from "./index.module.scss";
import usePageTitle from "@/hooks/usePageTitle";
interface PayOrderRuleProps {}

const PayOrderRule: React.FC<PayOrderRuleProps> = (props) => {
  const {} = props;
  usePageTitle({ title: "企业员工专享折扣说明" });
  return (
    <ApplyLayout>
      <div className={styles.card}>
        <div className={styles.header}>
          <div>平台提供给签约企业员工到店协议价服务</div>
          <div>企业开通后，员工到店消费立享超值协议价折扣优惠</div>
        </div>
        <div className={styles.content}>
          <div className={styles.item}>
            企业员工专享价仅限到店支付，请勿提前购买
          </div>
          <div className={styles.item}>
            折扣优惠请以买单页面显示折扣为准
          </div>
          <div className={styles.item}>
            如需发票，请您在消费时向商户咨询
          </div>
        </div>
      </div>
    </ApplyLayout>
  );
};

export default React.memo(PayOrderRule);
